export function formToObject(selector) {
    const o = {};
    const prnt = $(selector);

    $('input', prnt).each(function () {
        if (($(this).attr('type') === 'checkbox' || $(this).attr('type') === 'radio') && !$(this).prop('checked')) {
            return;
        }

        const name = $(this).attr('name');

        if (name?.length) {
            o[name] = $(this).val();
        }
    });

    $('textarea', prnt).each(function () {
        const name = $(this).attr('name');

        if (name?.length) {
            o[name] = $(this).val();
        }
    });

    return o;
}

export function checkEmailSimple(value) {
    let email = String(value);

    email = email.toLowerCase();

    const a = email.split('@');

    if (a.length !== 2) {
        return false;
    }

    const idog = email.indexOf('@');
    const idot = email.indexOf('.', idog);

    return (idot > idog);
}

export function formClearInputs(selector) {
    const prnt = $(selector);

    $('input', prnt).each(function () {
        if (
            (
                $(this).attr('type') === 'submit'
                || $(this).attr('type') === 'checkbox'
                || $(this).attr('type') === 'radio'
            )
            && !$(this).prop('checked')
        ) {
            return;
        }

        $(this).val('').blur();
    });

    $('textarea', prnt).each(function () {
        $(this).val('').blur();
    });
}