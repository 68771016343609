import { getUrlByInputs } from 'Lib/get-url-by-inputs/get-url-by-inputs';

const $mMenu = $('.m-menu');
const sfilterSubmit = '.m-menu__filter-submit';
const sFilterInput = '.m-menu__filter-input';

$('body').on('click', sfilterSubmit, () => {
    const urlGet = getUrlByInputs($mMenu.find(sFilterInput));

    localStorage.setItem('EksmoCatalogMenuPreviousFilters', JSON.stringify(urlGet));
});