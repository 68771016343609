import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import { Popup } from 'Lib/popup/popup';
import { isLocalhost } from 'Lib/is-localhost/is-localhost';
import { getCookie } from 'Lib/cookies/cookies';
import { eventSender } from 'Lib/event-sender/event-sender';

window.onload = function () {
    const elPopupUnified = document.querySelector('.popup-unified');

    if (elPopupUnified) {
        const startTime = new Date().getTime();
        const popupId = elPopupUnified.getAttribute('data-id');
        const objInfo = {
            'name': elPopupUnified.getAttribute('data-ga4-promo-name'),
            'id': elPopupUnified.getAttribute('data-ga4-promo-id'),
            'location_id': elPopupUnified.getAttribute('data-ga4-promo-location'),
        };

        if (getCookie(`EKSMO_POPUP_${popupId}_SHOWED`) !== 'Y') {
            const elPopupUnifiedBox = elPopupUnified.querySelector('.popup-unified__box');
            const delay = Number(elPopupUnified.getAttribute('data-delay'));
            const popupImg = elPopupUnified.getAttribute('data-img');
            const popupLink = elPopupUnified.getAttribute('data-link');
            const dataPopupEnd = Number(elPopupUnified.getAttribute('data-dt-end')) * 1000;
            const promises = [];

            if (popupImg && popupLink) {
                elPopupUnifiedBox.innerHTML = `
                    <a class="popup-unified__href-box" href="${popupLink}">
                        <img class="popup-unified__img popup-unified__img-load" data-src="${popupImg}" alt="">
                    </a>
                `;
            }

            $('.popup-unified__img-load').each((i, el) => {
                const $th = $(el);

                promises.push(new Promise((resolve) => {
                    $th.on('eksmoImgLoadSrcOrBg', resolve);
                    loadSrcOrBg($th);

                    return Promise.reject();
                }));
            });

            Promise.all(promises).then(() => {
                let date;
                const popup = new Popup('popup-unified');

                function openPopup() {
                    document.addEventListener('close.popup.eksmo', () => {
                        eventSender.customDL({ 'event': `ClickClose_Banner${popupId}` });
                    });

                    popup.open();
                    eventSender.customDL({ 'event': `View_Banner${popupId}` });
                    eventSender.promoImpressionsGA4(objInfo);

                    document.querySelector('.popup-unified__href-box')?.addEventListener('click', (e) => {
                        eventSender.promoClickGA4(objInfo);
                    });
                }

                if (isLocalhost()) {
                    date = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7);
                } else {
                    date = new Date(dataPopupEnd + 1000 * 60 * 60 * 24 * 7);
                }

                document.cookie = `EKSMO_POPUP_${popupId}_SHOWED=Y; path=/; domain=${window.location.hostname}; expires=${date.toUTCString()}; SameSite=None; Secure`;

                const timeLeft = new Date().getTime() - startTime;

                if (timeLeft < delay) {
                    setTimeout(() => {
                        openPopup();
                    }, delay - timeLeft);
                } else {
                    openPopup();
                }
            });
        }
    }
};
