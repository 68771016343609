import { Popup } from 'Lib/popup/popup';

const popupSwitchLanguageSwitch = new Popup('popup', document?.querySelector('.switch-language_popup-switch'));
const popupSwitchLanguageQuestion = new Popup('popup', document?.querySelector('.switch-language_popup-question'));

$('body').on('click', '.switch-language__item_popup-question', () => {
    popupSwitchLanguageSwitch.close();
    popupSwitchLanguageQuestion.open();
});

$('.switch-language__item_ru').on('click', function () {
    popupSwitchLanguageSwitch.close();
    $(this).closest('.cheader__switch-language-box').removeClass('link-drop-down_active');
});

$('.switch-language__popup-en-close').on('click', () => {
    popupSwitchLanguageQuestion.close();
});

$('.cheader__item_switch-language').on('click', () => {
    popupSwitchLanguageSwitch.open();
});
