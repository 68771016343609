import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

$('body').on('click', '.ajs', function (e) {
    e.preventDefault();

    const dataHref = $(this).attr('data-href');

    if (isNoEmptyString(dataHref)) {
        window.location.href = dataHref;
    }
});