import { speed } from 'Lib/speed/speed';
import { isIe } from 'Lib/is-ie/is-ie';

const $body = $('body');
const sInput = '.form__input';
const sInputLabel = '.form__input-label';
const cInputLabelActive = 'form__input-label_focus';
const sPasswordLink = '.form__password-link';
const cPasswordLinkShow = 'form__password-link_show';
const sItemPassword = '.form__input_password';

function setStateLabel($input, state = true) {
    let thisState = state;

    if (isIe()) {
        thisState = true;
    }

    if (!$input.hasClass('form__input_date')) {
        $input.closest(sInputLabel).toggleClass(cInputLabelActive, thisState);
    } else {
        $input.closest(sInputLabel).addClass(cInputLabelActive, thisState);
    }
}

function initLabel($input) {
    $input.each(function () {
        const $thisInput = $(this);

        if ($thisInput.val() !== '' || isIe()) {
            setStateLabel($thisInput, true);
        }
    });
}

initLabel($(sInput));

$body.on('focus', sInput, function () {
    setStateLabel($(this), true);
});

$body.on('blur', sInput, function () {
    const $input = $(this);

    if ($input.val() === '' && !isIe()) {
        setStateLabel($input, false);
    }
});

$body.on('click', sPasswordLink, function () {
    const $link = $(this);
    const $label = $link.closest(sInputLabel);

    if (!$link.hasClass(cPasswordLinkShow)) {
        $link.addClass(cPasswordLinkShow);
        $label.find(sItemPassword).attr('type', 'text');
    } else {
        $link.removeClass(cPasswordLinkShow);
        $label.find(sItemPassword).attr('type', 'password');
    }
});

$body.on('eksmoFancyboxAfterLoad', (e, instance, current) => {
    initLabel(current.$slide.find(sInput));
});

$('.form__form').on('eksmoThisAjaxFormSuccess', function () {
    const $this = $(this);
    const $parentFaq = $this.closest('.form_faq');
    const $title = $parentFaq.find('.form__faq-title').first();
    const $description = $parentFaq.find('.form__faq-description').first();

    if ($title.length) {
        $this.remove();
        $title.html('Спасибо!');
        $description.html('Мы обязательно прочтем ваше письмо');
        $('html, body').animate({ scrollTop: $parentFaq.offset().top - 100 }, speed);
    }
});