import { eksmoBP } from 'Lib/bp/bp';
import { speed } from 'Lib/speed/speed';
import 'imports-loader?wrapper=window!jquery-throttle-debounce'; // eslint-disable-line import/no-extraneous-dependencies, import/no-webpack-loader-syntax
import { getW } from 'Lib/w/w';
import { isLocalhost } from 'Lib/is-localhost/is-localhost';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';
import { oSelectbox } from 'Lib/selectbox/selectbox';
import { linkParsing } from 'Lib/link-parsing/link-parsing';
import { B24Form } from 'Lib/b24-form/b24-form';
import { eventSender } from 'Lib/event-sender/event-sender';

const urlPage = window.location.href;
const $body = $('body');
const cOpened = 'faq-bot_opened';
const $bot = $('.faq-bot').first();
const url = isLocalhost() ? '/eksmo/_verstka/ajax/faq-bot-test1.json' : '/faq/.questions.json';
const cWithFixSN = 'faq-bot_with-fix-sn';
const cWithFixSnSale = 'faq-bot_with-fix-sn-sale';
const classB24Form = new B24Form();
// old
let $containerForScroll = $bot.find('.faq-bot__container-for-scroll').first();

function init(d) {
    const $page = $bot.find('.faq-bot__page').first();
    const $pageTitle = $bot.find('.faq-bot__item_title-back').first();
    const sItem = '.faq-bot__item:not(.faq-bot__item_answer):not(.faq-bot__item_feedback)';
    const cBotFeedback = 'faq-bot_feedback';
    const sBtnFeedback = '.faq-bot__btn-feedback, .faq-bot__feedback-close';
    const $form = $('.faq-bot__form').first();
    const $formSuccess = $('.faq-bot__form-success').first();
    const cPageTitleShowed = 'faq-bot__item_title-back-showed';
    const cPageTitleCenter = 'faq-bot__item_title-back-center';
    const arData = getArray(d);
    const arrowBackHTML = '<div class="faq-bot__arrow-back"></div>';
    const cPageShowed = 'faq-bot__page_showed';
    const cPageAnimateHide = 'faq-bot__page_animate-hide';
    const $container = $bot.find('.faq-bot__container').first();
    const $containerForScrollCont = $bot.find('.faq-bot__container-for-scroll-cont').first();
    const cContainerWillScroll = 'faq-bot__container_will-scroll';
    const cContainerIsScroll = 'faq-bot__container_is-scroll';
    const cContainerIsScrollForTop = 'faq-bot__container_is-scroll-for-top';
    const $searchInput = $('.faq-bot__search-input').first();
    const $searchLoader = $('.faq-bot__search-loader').first();
    const cSearchLoaderShowed = 'faq-bot__search-loader_showed';
    const $searchEraser = $('.faq-bot__search-eraser').first();
    const cSearchEraserShowed = 'faq-bot__search-eraser_showed';
    let lastLevel = 0;
    let isSearchResultShowed = false;
    const cFormInputLevel = 'faq-bot__form-input-level';

    $containerForScroll = $bot.find('.faq-bot__container-for-scroll').first();

    function getArray(data, parent) {
        const arNewData = [];

        function setArray(arr, parentKey) {
            if (Array.isArray(arr)) {
                arr.forEach((item) => {
                    let lastPushedKey = null;

                    if (isNoEmptyString(item.QUESTION)) {
                        const o = { 'QUESTION': item.QUESTION };

                        if (isNoEmptyString(item.ANSWER)) {
                            o.ANSWER = item.ANSWER;
                        }

                        if (isNoEmptyString(item.CODE)) {
                            o.CODE = item.CODE;
                        }

                        if (isNoEmptyString(item.TYPE)) {
                            o.TYPE = item.TYPE;
                        }

                        if (typeof parentKey === 'number') {
                            o.PARENT = parentKey;
                        }

                        lastPushedKey = arNewData.push(o) - 1;
                    }

                    if (Array.isArray(item.ITEMS) && typeof lastPushedKey === 'number') {
                        setArray(item.ITEMS, lastPushedKey);
                    }
                });
            }
        }

        setArray(data, parent);

        return arNewData;
    }

    function getFirstLevel() {
        if (Array.isArray(arData)) {
            const ids = [];

            arData.forEach((item, k) => {
                if (typeof item.PARENT === 'undefined') {
                    ids.push(k);
                }
            });

            return ids;
        }

        return [];
    }

    function getQuestionHTML(arr, firstLevel = false) {
        let html = '';

        if (firstLevel && linkParsing.getTypeBook(urlPage)) {
            html = '<div class="faq-bot__item faq-bot__btn-feedback" data-title="Есть замечания по книге" data-remarks="true"><div class="faq-bot__item-svg-box"><svg class="faq-bot__item-svg"><use xlink:href="#addition"></use></svg></div>Есть замечания по книге</div>';
        }

        if (Array.isArray(arr)) {
            arr.forEach((k) => {
                html += `<div class="faq-bot__item" data-id="${k}"${(isNoEmptyString(arData[k].CODE)) ? ` data-code="${arData[k].CODE}"` : ''}${(isNoEmptyString(arData[k].TYPE)) ? ` data-type="${arData[k].TYPE}"` : ''}><div class="faq-bot__item-svg-box"><svg class="faq-bot__item-svg"><use xlink:href="#file"></use></svg></div>${arData[k].QUESTION}</div>`;
            });
        }

        return html;
    }

    function getAnswerHTML(o) {
        let html = '';

        if (isNoEmptyString(o.QUESTION) && isNoEmptyString(o.ANSWER)) {
            html += `
                <div class="faq-bot__item faq-bot__item_wrap faq-bot__item_answer">
                    <div class="faq-bot__answer-title">${o.QUESTION}</div>
                    ${o.ANSWER}
                </div>
            `;
        }

        return html;
    }

    function setPage(itemsHTML) {
        $page.removeClass(cPageShowed);
        $page.addClass(cPageAnimateHide);

        setTimeout(() => {
            $page.removeClass(cPageAnimateHide);

            setTimeout(() => {
                $page.html(itemsHTML);
                $page.addClass(cPageShowed);
                setTimeout(() => setScrollStyle, speed);
            }, speed);
        }, speed);
    }

    function setPageTitle(text, id, isStyleCenter) {
        $pageTitle.removeClass(cPageTitleShowed).html('').removeAttr('data-id');

        if (typeof id === 'number' && !Number.isNaN(id)) {
            $pageTitle.attr({ 'data-id': id }).addClass(cPageTitleShowed);

            if (isNoEmptyString(text)) {
                $pageTitle.html(`${arrowBackHTML}<span>${text}</span>`);
            } else {
                $pageTitle.html(`${arrowBackHTML}<span>Вернуться назад</span>`);
            }

            $pageTitle.toggleClass(cPageTitleCenter, !!isStyleCenter);
        }
    }

    function setLevel(i, code, type) {
        let id = i;

        if (typeof id !== 'number' && typeof id !== 'string') {
            setPage(getQuestionHTML(getFirstLevel(), true));
            lastLevel = 0;
        } else {
            if (typeof id === 'string') {
                id = parseInt(id);
            }

            if (!Number.isNaN(id)) {
                setPageTitle('Вернуться назад', id);

                if (typeof arData[id].ANSWER === 'string') {
                    setPage(getAnswerHTML(arData[id]));
                } else {
                    const arChildren = [];

                    arData.forEach((item, k) => {
                        if (item.PARENT === id) {
                            arChildren.push(k);
                        }
                    });

                    setPage(getQuestionHTML(arChildren));
                }

                lastLevel = id;
            } else {
                lastLevel = 0;
            }
        }

        setInputLevel(code, type);
    }

    function getIDFromSearch(value) {
        let words = value;
        const arRes = [];

        if (typeof words === 'string') {
            words = words.trim();

            if (words.length) {
                arData.forEach((item, k) => {
                    const q = item.QUESTION;

                    if (typeof q === 'string' && q.length && q.toLowerCase().includes(words.toLowerCase())) {
                        arRes.push(k);
                    }
                });
            }
        }

        return arRes;
    }

    function setSearchResult(arRes) {
        if (isSearchResultShowed) {
            $bot.removeClass(cBotFeedback);
            setPageTitle('Результаты поиска', lastLevel, true);

            if (Array.isArray(arRes) && arRes.length) {
                setPage(getQuestionHTML(arRes));
            } else {
                setPage(`<div class="faq-bot__item faq-bot__item_answer">Ничего не найдено!</div>`);
            }
        }
    }

    function clearSearchInput() {
        $searchEraser.removeClass(cSearchEraserShowed);
        $searchInput.val('');
    }

    function setScrollStyle() {
        if ($container.length && $containerForScroll.length && $containerForScrollCont.length) {
            const containerForScrollH = $containerForScroll.height();
            const containerForScrollContH = $containerForScrollCont.height();

            $container.toggleClass(cContainerWillScroll, (containerForScrollContH > containerForScrollH));

            $container.toggleClass(
                cContainerIsScroll,
                (
                    containerForScrollContH > containerForScrollH
                    && ($containerForScroll.scrollTop() + 10) < (containerForScrollContH - containerForScrollH)
                ),
            );

            $container.toggleClass(cContainerIsScrollForTop, (containerForScrollContH > containerForScrollH && $containerForScroll.scrollTop() > 10));
        }
    }

    function setInputLevel(code, type) {
        const $formInputLevel = $form.find(`.${cFormInputLevel}`);

        if (typeof code === 'undefined') {
            $formInputLevel.remove();
        } else if ($formInputLevel.length) {
            $formInputLevel.val(code);
        } else {
            $form.prepend(`<input type="hidden" name="level" class="${cFormInputLevel}" value="${code}">`);
        }

        if (typeof type === 'undefined') {
            $(sBtnFeedback).removeAttr('data-type');
        } else {
            $(sBtnFeedback).attr('data-type', type);
        }
    }

    setLevel();

    $page.on('click', sItem, function () {
        const $this = $(this);

        clearSearchInput();
        setLevel($this.attr('data-id'), $this.attr('data-code'), $this.attr('data-type'));
        $containerForScroll.scrollTop(0);

        eventSender.customDL({
            event: 'eksmo',
            eventAction: 'click',
            eventCategory: 'FAQ-widget',
            eventLabel: $(this).text(),
        });
    });

    $pageTitle.on('click', function () {
        let id = $(this).attr('data-id');

        if (isNoEmptyString(id)) {
            id = parseInt(id);

            if (!Number.isNaN(id)) {
                if (typeof arData[id].PARENT === 'number') {
                    setPageTitle('Вернуться назад', arData[id].PARENT);
                    setLevel(arData[id].PARENT, arData[arData[id].PARENT].CODE, arData[arData[id].PARENT].TYPE);
                } else {
                    setPageTitle();
                    setLevel();
                }

                $searchEraser.removeClass(cSearchEraserShowed);
                $searchInput.val('');
                isSearchResultShowed = false;
                $containerForScroll.scrollTop(0);
            }
        }
    });

    $body.on('click', sBtnFeedback, (e) => {
        const target = e.target;

        if (target.getAttribute('data-type') === 'corp_client') {
            classB24Form.setEventInitedBitrix();

            if (window.location.hostname === 'eksmo.ru') {
                classB24Form.loadPopupForm('inline/3/2ntrw7', 'https://crm.eksmo.ru/upload/crm/form/loader_3_2ntrw7.js');
            } else {
                classB24Form.loadPopupForm('inline/3/2ntrw7', 'https://dev-i.eksmo.ru/upload/crm/form/loader_3_2ntrw7.js');
            }
        } else if (target.getAttribute('data-type') === 'opt_client') {
            classB24Form.setEventInitedBitrix();

            if (window.location.hostname === 'eksmo.ru') {
                classB24Form.loadPopupForm('inline/10/e4im7j', 'https://crm.eksmo.ru/upload/crm/form/loader_10_e4im7j.js');
            } else {
                classB24Form.loadPopupForm('inline/8/p6fl2a', 'https://dev-i.eksmo.ru/upload/crm/form/loader_8_p6fl2a.js');
            }
        } else {
            const formTitle = target.getAttribute('data-title');
            const formRemarks = target.getAttribute('data-remarks');

            if (formTitle) {
                $('.faq-bot__feedback-close span').text(formTitle);
            }

            if (formRemarks && linkParsing.getTypeBook(urlPage)) {
                const bookId = $('.book-page')?.first()?.attr('data-book-id');
                const typeBook = linkParsing.getTypeBook(urlPage);

                document.querySelector('.faq-bot__form .faq-bot__form-item').insertAdjacentHTML('beforeBegin', `<div class="faq-bot__form-item selectbox faq-bot__selectbox"><input type="hidden" value="${typeBook}" name="book_type"><input type="hidden" value="${bookId}" name="book_nomcode"><select name="book_note_theme"><option class="selectbox__option-placeholder" value="Выберите тему сообщения">Выберите тему сообщения</option><option value="Тема сообщения - Ошибка в тексте">Ошибка в тексте</option><option value="Тема сообщения - Брак">Брак</option><option value="Тема сообщения - Другое">Другое</option></select></div>`);
                oSelectbox.init('.selectbox');
            } else {
                document.querySelector('.faq-bot__selectbox')?.parentNode.removeChild(document.querySelector('.faq-bot__selectbox'));
            }

            $bot.toggleClass(cBotFeedback);
            setScrollStyle();
            $containerForScroll.scrollTop(0);
        }
    });

    $(window).add($containerForScroll).on('scroll', $.debounce(speed, setScrollStyle));
    $containerForScroll.on('scroll', $.debounce(speed / 10, setScrollStyle));

    // нажатие на клавиши клавиатуры
    $searchInput.on('keyup paste', function (e) {
        const val = $.trim($(this).val());

        if (
            e.keyCode !== 40
            && e.keyCode !== 38
            && e.keyCode !== 27
            && e.keyCode !== 13
            && e.keyCode !== 16
            && e.keyCode !== 17
            && e.keyCode !== 18
            && e.keyCode !== 19
            && e.keyCode !== 20
            && e.keyCode !== 33
            && e.keyCode !== 34
            && e.keyCode !== 35
            && e.keyCode !== 36
            && e.keyCode !== 37
            && e.keyCode !== 39
            && e.keyCode !== 44
            && e.keyCode !== 45
            && e.keyCode !== 91
            && e.keyCode !== 144
        ) { // кнопки, не обновляющие список такие как alt и shift
            $searchLoader.toggleClass(cSearchLoaderShowed, val.length > 2);
            $searchEraser.toggleClass(cSearchEraserShowed, !!val.length);
        }
    });

    // нажатие на клавиши клавиатуры с задержкой
    $searchInput.on('keyup paste', $.debounce(speed, function (e) {
        const val = $.trim($(this).val());

        if (
            e.keyCode !== 40
            && e.keyCode !== 38
            && e.keyCode !== 27
            && e.keyCode !== 13
            && e.keyCode !== 16
            && e.keyCode !== 17
            && e.keyCode !== 18
            && e.keyCode !== 19
            && e.keyCode !== 20
            && e.keyCode !== 33
            && e.keyCode !== 34
            && e.keyCode !== 35
            && e.keyCode !== 36
            && e.keyCode !== 37
            && e.keyCode !== 39
            && e.keyCode !== 44
            && e.keyCode !== 45
            && e.keyCode !== 91
            && e.keyCode !== 144
        ) { // исключая кнопки не обновляющие список такие как alt и shift
            let arRes = [];

            if (val.length > 2) {
                arRes = getIDFromSearch(val);
                $searchLoader.removeClass(cSearchLoaderShowed);
                isSearchResultShowed = true;
            } else {
                $searchLoader.removeClass(cSearchLoaderShowed);
            }

            setSearchResult(arRes);
        }
    }));

    $searchEraser.on('click', () => {
        clearSearchInput();
        setSearchResult();
        isSearchResultShowed = false;
        $($searchInput).first().focus();
    });

    $form.on('eksmoThisAjaxFormSuccess', (e, text) => {
        if ($formSuccess.length && typeof text === 'string') {
            $formSuccess.html(text);
        }
    });
}

$bot.on('click', '.faq-bot__btn, .faq-bot__close', function () {
    if (!$bot.find('.faq-bot__container_temp').length) {
        // old
        if (!$bot.find('.faq-bot__container').hasClass('faq-bot__container_temp')) {
            $bot.find('.faq-bot__container').remove();
        }

        if ($(this).hasClass('faq-bot__btn')) {
            eventSender.customDL({
                event: 'eksmo',
                eventAction: 'click',
                eventCategory: 'FAQ-widget',
                eventLabel: 'start',
            });
        }

        $bot.append(`
<div class="faq-bot__container faq-bot__container_temp">
<div class="faq-bot__container-for-scroll">
    <div class="faq-bot__container-for-scroll-cont">
        <div class="faq-bot__top">
            <div class="faq-bot__top-cont">
                <div class="faq-bot__top-title">Нужна помощь?</div>
                <div class="faq-bot__search">
                    <input type="text" name="text" class="faq-bot__search-input" placeholder="Поиск в справочнике">
                    <svg class="faq-bot__search-loader"><use xlink:href="#loading-motion"></use></svg>
                    <div class="faq-bot__search-eraser"></div>
                    <div class="faq-bot__search-btn">
                        <svg class="faq-bot__search-btn-svg"><use xlink:href="#search"></use></svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="faq-bot__content">
            <div class="faq-bot__content-item">
                <div class="faq-bot__item faq-bot__item_title faq-bot__item_title-back"></div>

                <div class="faq-bot__page faq-bot__page_showed">
                    <div class="faq-bot__loader-icon loader loader_center loader_small loader_orange">
                        <div class="loader__cont">
                            <svg class="loader__svg"><use xlink:href="#loader"></use></svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="faq-bot__content-item">
                <div class="faq-bot__item faq-bot__item_wrap faq-bot__item_title">
                    <div class="faq-bot__item-title-feedback">Не нашли ответа?</div>
                    <div class="btn btn_orange btn_full-width faq-bot__btn-feedback" data-title="Задать вопрос">
                        <svg class="btn__icon"><use xlink:href="#envelope-color"></use></svg>
                        Напишите нам
                    </div>
                </div>
            </div>
            <div class="faq-bot__feedback">
                <div class="faq-bot__content-item">
                    <div
                        class="
                            faq-bot__item
                            faq-bot__item_title
                            faq-bot__item_title-back
                            faq-bot__item_title-back-showed
                            faq-bot__item_title-back-center
                            faq-bot__feedback-close
                        "
                        data-id="0"
                    >
                        <div class="faq-bot__arrow-back"></div>
                        <span>Задать вопрос</span>
                    </div>
                    <div class="faq-bot__page faq-bot__page_showed">
                        <div class="faq-bot__item faq-bot__item_feedback">
                            <form method="post" class="faq-bot__form ajax-form ajax-form_loader-in-btn ajax-form_rr ajax-form_oa" data-fn="faq">
                                <div class="ajax-form__rr-cont">
                                    <input type="hidden" class="ajax-form__action" value="/ajax/faq_form/">
                                    <input type="hidden" name="subject" class="ajax-form__action" value="Попап">
                                    <div class="faq-bot__form-item">
                                        <input type="text" name="name" value="" class="faq-bot__form-input" placeholder="Имя">
                                    </div>
                                    <div class="faq-bot__form-item">
                                        <input type="email" name="email" value="" class="faq-bot__form-input" placeholder="E-mail">
                                    </div>
                                    <div class="faq-bot__form-item">
                                        <textarea
                                            name="message"
                                            class="faq-bot__form-input faq-bot__form-input_textarea"
                                            placeholder="Текст сообщения"
                                        ></textarea>
                                    </div>
                                    <div class="faq-bot__form-item faq-bot__form-item_btn">
                                        <button class="btn btn_orange btn_font-medium btn_full-width ajax-form__btn" type="submit">
                                            <span class="ajax-form__btn-text">
                                                <svg class="btn__icon"><use xlink:href="#envelope-color"></use></svg>
                                                Отправить
                                            </span>
                                            <svg class="ajax-form__btn-loader"><use xlink:href="#loading-motion"></use></svg>
                                        </button>
                                    </div>
                                    <div class="ajax-form__response faq-bot__form-response"></div>
                                </div>
                                <div class="ajax-form__rr-response">
                                    <div class="faq-bot__form-success"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="faq-bot__close"></div>
</div>
        `);

        $.ajax({
            url,
            cache: false,
            timeout: 3000,
            success: (value) => {
                let data = value;

                if (typeof data !== 'object') {
                    data = $.parseJSON(data);
                }

                if (Array.isArray(data) && data.length) {
                    init(data);
                }
            },
        });
    }

    setTimeout(() => {
        $containerForScroll.scrollTop(0);
        $bot.toggleClass(cOpened, !$bot.hasClass(cOpened));
    }, 10);
});

function setClasses() {
    if ($('.article-share.article-share_fixed').length) {
        let $header = $('.header').first();
        const $newHeader = $('.cheader').first();
        const $saleFixed = $('.sale.sale_fixed');

        if (!$header.length) {
            $header = $newHeader;
        }

        $bot.addClass(cWithFixSN);
        $bot.toggleClass(cWithFixSnSale, ($saleFixed.length && !$saleFixed.closest($header).length && getW() < eksmoBP.mdMin));
    } else {
        $bot.removeClass(cWithFixSN);
        $bot.removeClass(cWithFixSnSale);
    }
}

if ($bot.length) {
    setClasses();
    $bot.on('eksmoCallFaqBotSetClasses', setClasses);
    $body.on('eksmoResizeWidth', setClasses);
}