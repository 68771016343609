import { getCookie } from 'Lib/cookies/cookies';
import { onScreen } from 'Lib/on-screen/on-screen';

const $body = $('body');
const alertChangeAge = 'Изменить свой возраст можно только в Личном кабинете после регистрации.';

function openPopup18(text, control = false) {
    $body.append(`
        <div class="popup18 popup18_full-screen popup18_popup">
            <div class="popup18__bg popup18__bg_blur">
                <div class="popup18__popup">
                    <button class="popup18__close"></button>
                    <div class="popup18__title h h_1 h_no-offset h_center">Внимание!</div>
                    <div class="popup18__text">${text}</div>

                    ${control ? `
                        <div class="popup18__controls">
                            <button class="popup18__btn popup18__btn_y btn btn_border btn_uc">Да</button>
                            <button class="popup18__btn popup18__btn_n exclusive-books__18-popup-btn-n btn btn_border btn_uc">Нет</button>
                        </div>` : ''}
                </div>
            </div>
        </div>
    `);
}

function closePopup18() {
    const date = new Date(new Date().getTime() + 60 * 1000 * 60 * 24 * 30);

    document.cookie = `EKSMO_BOOKS_POPUP_18=N; path=/; domain=${window.location.hostname}; expires=${date.toUTCString()}; SameSite=None; Secure`;
    $('.popup18_popup').remove();
}

export function deleteActivePopup18() {
    if (getCookie(`EKSMO_BOOKS_POPUP_18`) === 'Y') {
        $('.popup18_active').removeClass('popup18_active');
    } else {
        onScreen($('.popup18_active'), () => {
            if (!getCookie(`EKSMO_BOOKS_POPUP_18`) && !$('.popup18_popup').length) {
                openPopup18('На этой странице содержится контент 18+. Вам уже исполнилось 18 лет?', true);
            }
        });
    }
}

$body.on('click', ".popup18_active:not('.book-page')", (e) => {
    const target = e.target;

    if (target.closest('.popup18__img')
        || target.closest('.book-preview__description-text')) {
        e.preventDefault();
        openPopup18(alertChangeAge);
    }
});

$body.on('click', '.popup18_active.book-page', (e) => {
    const target = e.target;

    if (target.closest('.book-page__card-description')
        || target.closest('.book-page__cover-slides-cont')
        || target.closest('.content-slider__item-pic')) {
        e.preventDefault();
        openPopup18(alertChangeAge);
    }
});

$body.on('click', '.popup18__btn_y', () => {
    const date = new Date(new Date().getTime() + 60 * 1000 * 60 * 24 * 365 * 10); // на 10 лет

    document.cookie = `EKSMO_BOOKS_POPUP_18=Y; path=/; domain=${window.location.hostname}; expires=${date.toUTCString()}; SameSite=None; Secure`;
    $('.popup18_popup').remove();
    $('.popup18_active').removeClass('popup18_active');
});

$body.on('click', '.popup18__bg', (e) => {
    if (!e.target.closest('.popup18__popup')) {
        closePopup18();
    }
});

deleteActivePopup18();
$body.on('click', '.popup18__btn_n, .popup18__close', closePopup18);
$('.books-slider_post-load').on('eksmoBooksSliderPostLoaded', deleteActivePopup18);
$body.on('init.fastViewBook', deleteActivePopup18);
$body.on('eksmoPagenavAjax', deleteActivePopup18);