import { oGrecaptcha } from 'Lib/grecaptcha/grecaptcha';
import { formClearInputs, formToObject } from 'Lib/o-form-tools/o-form-tools';
import { getW } from 'Lib/w/w';
import { CheckboxAccept } from 'Lib/checkbox-accept/checkbox-accept';
import { showReg } from 'Lib/show-reg/show-reg';
import { showAuthFancy } from 'Lib/auth/auth';
import { inclination } from 'Lib/inclination/inclination';

const sLinkMail = '.auth-form__link-mail';
const sControls = '.auth-form__mail-control';
const sMail = '.auth-form__mail';
const sLinkFancy = '.auth-form__fancy-link';
const sInputReq = '.auth-form__input';
const cInputReqTrue = 'auth-form__input_checked';
const cInputError = 'form__input_error';
const sForm = '.auth-form__form';
const sSubmit = '.auth-form__submit';
const sResponse = '.auth-form__response';
const cFormAuth = 'auth-form__form_auth';
const cFormReg = 'auth-form__form_reg';
const cFormForgot = 'auth-form__form_forgot';
const sFormBtnSN = '.auth-form__btn-sn';
const checkboxes = new CheckboxAccept(
    'registration',
    '.auth-form__form_reg',
    '.auth-form__form_reg .checkbox_required input',
    '.auth-form__form_reg .checkbox_required .checkbox__content-offset',
    null,
    document.querySelector('.auth-form__form_reg'),
);

checkboxes.setEventCheckboxAccept(true);

function showSubmit($s, bool) {
    if ($s.length) {
        const cSubmitDisabled = 'btn_disabled';

        if (bool) {
            $s.removeAttr('disabled').removeClass(cSubmitDisabled);
        } else {
            $s.attr('disabled', 'disabled').addClass(cSubmitDisabled);
        }
    }
}

function postForm(o, $frm) {
    const $submit = $frm.find(sSubmit).first();
    const href = $frm.attr('action');
    const $resp = $frm.find(sResponse);

    if ($submit.attr('data-loaded') === 'Y') {
        return false;
    }

    $submit.attr('data-loaded', 'Y');
    $submit.html('&nbsp;<svg class="btn__icon btn__icon_center btn__icon_big auth-form__loading"><use xlink:href="#loading"></use></svg>');
    o.ajax = 'Y';

    function curPost() {
        o.http_referer = document.referrer;

        $.post(href, o, (data) => {
            const $cont = $frm.closest('.auth-form__vcenter');

            if ($frm.hasClass(cFormReg) && data.indexOf('success') > 0 && $cont.length) {
                $cont.html('<div class="auth-form__cont-reg-success"><svg class="auth-form__svg-reg-success"><use xlink:href="#hand-color"></use></svg><div class="h h_1 h_center h_no-offset auth-form__title">Добро пожаловать!</div><div class="form form_width form_center"><div class="form__text form__text_center">Для завершения регистрации остался один шаг - пожалуйста, пройдите по ссылке в письме.</div></div></div>');
            } else {
                $resp.html(data);

                if ($frm.hasClass(cFormAuth) && data.indexOf('success') > 0) {
                    formClearInputs($frm);
                    $.fancybox.close();
                } else {
                    $submit.html($submit.attr('data-txt'));
                    $submit.attr('data-loaded', 'N');
                }
            }
        });
    }

    if (($frm.hasClass(cFormReg) || $frm.hasClass(cFormForgot)) && $frm.find('input[type=email]').first().val() !== 'AutotestEksmoRu@eksmo.ru') {
        oGrecaptcha.render($submit);

        oGrecaptcha.check($submit).done((resp) => {
            o['g-recaptcha-response'] = resp;
            curPost();
        });
    } else {
        curPost();
    }

    return false;
}

function snPopup(href, w, h) {
    let width = w;
    let height = h;

    if (typeof href === 'string') {
        if (href.length) {
            if (typeof width !== 'string') {
                width = 680;
            }

            if (typeof height !== 'string') {
                height = 600;
            }

            return window.open(href, '', `status=no,scrollbars=yes,resizable=yes,width=${width},height=${height},top=${Math.floor(($(window).height() - height) / 2 - 14)},left=${Math.floor((getW() - width) / 2 - 5)}`);
        }
    }

    return false;
}

$('body').on('click', sLinkMail, (e) => {
    e.preventDefault();
    $(sControls).hide();
    $(sMail).show();
}).on('click', `.auth-form_reg ${sLinkFancy}`, function (e) {
    e.preventDefault();

    const href = $(this).attr('href');

    showAuthFancy(href);
}).on('click', `.auth-form_auth ${sLinkFancy}`, function (e) {
    e.preventDefault();

    const href = $(this).attr('href');

    showReg(href);
}).on('change keyup paste focus blur', sInputReq, function () {
    const $form = $(this).closest(sForm);
    const $submit = $form.find(sSubmit);
    const attrName = $(this).attr('data-name');
    const val = $(this).val();
    const minPassLength = 6;
    const $passwordInfoBox = $('.auth-form__password-info-box');
    const $passwordInfo = $passwordInfoBox.find('.auth-form__password-info');

    $(this).removeClass(cInputError);

    if (attrName === 'email') {
        const patternEmail = /^([a-z0-9_.-])+@[a-z0-9-]+\.([a-z0-9]{2,32}\.)?[a-z0-9]{2,32}$/i;

        if (patternEmail.test(val)) {
            $(this).addClass(cInputReqTrue);
        } else {
            $(this).removeClass(cInputReqTrue);
        }
    } else if (attrName === 'checkbox' && $(this).hasClass('auth-form__input_required')) {
        if ($(this).prop('checked')) {
            $(this).addClass(cInputReqTrue);
        } else {
            $(this).removeClass(cInputReqTrue);
        }
    } else if (attrName === 'password') {
        if (val.length >= minPassLength) {
            $(this).addClass(cInputReqTrue);
            $passwordInfoBox.removeClass('auth-form__password-info-box_red').addClass('auth-form__password-info-box_green');
            $passwordInfo.text(`Допустимая длина пароля`);
            $('.auth-form__password-info-line').css({ 'width': '100%' });
        } else {
            $(this).removeClass(cInputReqTrue);
            $passwordInfoBox.addClass('auth-form__password-info-box_red').removeClass('auth-form__password-info-box_green');
            $passwordInfo.text(`Слишком короткий пароль, добавьте ещё ${minPassLength - val.length} ${inclination(minPassLength - val.length, ['символ', 'символа', 'символов'])}`);
            $('.auth-form__password-info-line').css({ 'width': `${(val.length / minPassLength) * 100}%` });
        }
    } else if (val.length) {
        $(this).addClass(cInputReqTrue);
    } else {
        $(this).removeClass(cInputReqTrue);
    }

    const qtyInputTrue = $form.find(`.${cInputReqTrue}`).length;

    if (qtyInputTrue === $form.find(sInputReq).length) {
        const $checkboxForm = $form.find('.checkbox_required input');

        if (!$checkboxForm.length) {
            showSubmit($submit, true);
        } else if ($checkboxForm.is(':checked')) {
            showSubmit($submit, true);
        }
    } else {
        showSubmit($submit, false);
    }
}).on('submit', sForm, function (e) {
    const $frm = $(this);
    const o = formToObject($frm);

    if (typeof $frm.attr('data-fn') !== 'undefined') {
        $.get(`/ajax/form/?fn=${$frm.attr('data-fn')}`, (data) => {
            if (data.length) {
                o.hash = data;
                postForm(o, $frm);
            }
        });
    } else {
        postForm(o, $frm);
    }

    e.stopPropagation();

    return false;
}).on('click', sFormBtnSN, function (e) {
    const $this = $(this);

    e.preventDefault();
    snPopup($this.attr('data-href'), $this.attr('data-width'), $this.attr('data-height'));
});