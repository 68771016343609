export class B24Form {
    static _formInited(obj) {
        window.addEventListener('b24:form:init', (event) => {
            const form = event.detail.object;

            if (obj && Object.keys(obj) && Object.keys(obj).length !== 0) {
                Object.keys(obj).forEach((key) => {
                    if (obj[key]) {
                        form.setProperty(key, obj[key]);
                    }
                });
            }

            form.setProperty('from_url', window.location.href);
            form.setProperty('page_title', document.querySelector('title').textContent);
        });
    }

    setEventInitedBitrix(obj) {
        this._obj = obj;
        B24Form._formInited(this._obj);
    }

    loadPopupForm(name, url) {
        (function (w, d, u) {
            document.querySelector('.b24-script')?.remove();

            const s = d.createElement('script');

            // eslint-disable-next-line no-bitwise
            s.src = `${u}?${Date.now() / 180000 | 0}`;
            s.setAttribute('data-b24-form', name);
            s.setAttribute('data-skip-moving', 'true');
            s.classList.add('b24-script');

            const h = d.getElementsByTagName('script')[0];

            h.parentNode.insertBefore(s, h);

            s.onload = function () {
                setTimeout(() => {
                    const htmlPopup = $('.b24-form').first().detach();

                    $.fancybox.open({
                        type: 'html',
                        src: htmlPopup,
                        touch: false,
                        afterLoad: () => {
                            document.dispatchEvent(new CustomEvent('open.popup.eksmo'));
                        },
                    });
                }, 300);
            };
        }(window, document, url));
    }
}