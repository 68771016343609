import { isTouch } from 'Lib/istouch/istouch';

class Swipe {
    constructor(element, minSwipeSize) {
        if (element instanceof Element) {
            this.onUp = null;
            this.onRight = null;
            this.onDown = null;
            this.onLeft = null;
            this._element = element;
            this._minSwipeSize = parseInt(minSwipeSize) || 0;
            this._startX = null;
            this._starty = null;

            if (isTouch()) {
                this._element.addEventListener('touchstart', this._onTouchStart);
                this._element.addEventListener('touchmove', this._onTouchMove);
            }
        }
    }

    _onTouchStart = (e) => {
        // Если несколько пальцев
        if (e.touches.length !== 1) {
            return;
        }

        // Установка стартовых координат
        this._startX = e.touches[0].clientX;
        this._startY = e.touches[0].clientY;
    }

    _onTouchMove = (e) => {
        // Если еще не сработал this._onTouchStart
        if (!this._startX || !this._startY) {
            return;
        }

        const endX = e.touches[0].clientX;
        const endY = e.touches[0].clientY;
        const diffX = this._startX - endX;
        const diffY = this._startY - endY;
        const diffXAbs = Math.abs(diffX);
        const diffYAbs = Math.abs(diffY);

        // Если не достаточное смещение
        if (Math.max(diffXAbs, diffYAbs) < this._minSwipeSize) {
            return;
        }

        if (diffXAbs > diffYAbs) {
            if (diffX > 0) { // swipe влево
                this.onLeft?.();
            } else { // swipe вправо
                this.onRight?.();
            }
        } else if (diffY > 0) { // swipe вверх
            this.onUp?.();
        } else { // swipe вниз
            this.onDown?.();
        }

        // Обнуляем начальные координаты по окончанию свайпа
        this._startX = null;
        this._starty = null;
    }

    destroy = () => {
        this._element.removeEventListener('touchstart', this._onTouchStart);
        this._element.removeEventListener('touchmove', this._onTouchMove);
    }
}

export { Swipe };