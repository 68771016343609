import { getCookie } from 'Lib/cookies/cookies';

const $body = $('body');
const cookieName = 'COOKIE_ACCEPT';

function setCookie(name) {
    const date = new Date(new Date().getTime() + 60 * 1000 * 60 * 24 * 365 * 10); // на 10 лет

    document.cookie = `EKSMO_${name}_SHOWED=Y; path=/; domain=${window.location.hostname}; expires=${date.toUTCString()}; SameSite=None; Secure`;
}

$body.on('bitrixDynamicComposite.eksmo', () => {
    if (!$('html').hasClass('is-authorized')) {
        if (getCookie(`EKSMO_${cookieName}_SHOWED`) !== 'Y') {
            $(document).ready(() => {
                $body.append(`
                    <div class="cookie-accept bg bg_lighterblack">
                        <div class="container">
                            <div class="cookie-accept__row">
                                <div class="cookie-accept__left">
                                    <div class="cookie-accept__text">
                                        <div class="hidden-xs hidden-sm">
                                            Мы используем файлы cookie и другие средства сохранений предпочтений и анализа действий посетителей сайта.
                                            <br>Подробнее в <a href="/publishers/polzovatelskoe-soglashenie/" class="a a_orange a_noline">пользовательском соглашении</a>.
                                            Нажмите "принять", чтобы дать свое согласие на это.
                                        </div>

                                        <div class="visible-xs visible-sm">
                                            <a href="/publishers/polzovatelskoe-soglashenie/" class="a a_orange a_noline">Мы используем куки</a>, чтобы пользоваться сайтом было удобно
                                        </div>
                                    </div>
                                </div>
                                <div class="cookie-accept__right">
                                    <div class="cookie-accept__btn btn btn_small btn_full-width btn_orange">
                                        <div class="visible-xs visible-sm">Хорошо</div>
                                        <div class="hidden-xs hidden-sm">Принять</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                `);
            });
        }
    }
});

$body.on('click', '.cookie-accept__btn', () => {
    setCookie(cookieName);
    $('.cookie-accept').remove();
});