import { isLocalStorageEnabled } from 'Lib/is-local-storage-enabled/is-local-storage-enabled';
import { getCookie } from 'Lib/cookies/cookies';
import { eventSender } from 'Lib/event-sender/event-sender';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';
import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';
import { CheckboxAccept } from 'Lib/checkbox-accept/checkbox-accept';
import setBooksLike from 'Lib/white-label/include/setBooksLike';
import { isLocalhost } from 'Lib/is-localhost/is-localhost';

// Виды систем оплаты
const PAYMENT_TYPES = Object.freeze({
    28: {
        title: 'Оплата онлайн банковской картой',
    },
    31: {
        title: 'Оплата онлайн банковской картой',
    },
    10: {
        title: 'Оплата онлайн банковской картой',
    },
    17: {
        title: 'Qiwi-кошелек',
    },
    15: {
        title: 'WebMoney',
    },
    13: {
        title: 'ЮMoney',
    },
    22: {
        title: 'Внутренний счет',
    },
    19: {
        title: 'Оплата безналичным расчетом',
    },
    26: {
        title: 'Оплата сертификатом',
    },
    18: {
        title: 'Банковский перевод',
    },
    2: {
        title: 'Наложенный платеж',
    },
    1: {
        title: 'Оплата при получении',
    },
});
const url = new URL(window.location);
const $body = $('body');
const basketItemsCommon = [];
let basketItemsCommonNew = [];
let basketMindbox = [];
let promocode;
let basketItemsDeleted = [];
let isCheaderWasFixed = false;
const checkboxes = new CheckboxAccept(
    'cart',
    '.order-recipient-inner',
    '.white-label-additional-agreement-checkbox input',
    '.white-label-additional-agreement-checkbox .checkbox__content-offset',
);

checkboxes.setEventCheckboxAccept();

function getCity() {
    if (isLocalStorageEnabled()) {
        const name = localStorage.getItem('BITRIX_SM_location_name');

        if (name) {
            return typeof name === 'string' ? name : '';
        }
    }

    return '';
}

function getCityCode() {
    if (isLocalStorageEnabled()) {
        const code = localStorage.getItem('BITRIX_SM_location_code');

        if (code) {
            return typeof code === 'string' ? code : '';
        }
    }

    return '';
}

if (!getCookie('eid')) {
    const date = new Date(new Date().getTime() + 60 * 1000 * 60 * 24 * 365 * 10); // на 10 лет
    const minRand = 100000;
    const maxRand = 1000000;
    const rand = Math.floor(Math.random() * (maxRand - minRand) + minRand);
    const curDate = new Date().getTime();
    const id = `${rand}${curDate}`;

    document.cookie = `eid=${id}; path=/; domain=${window.location.hostname}; expires=${date.toUTCString()}; SameSite=None; Secure`;
}

export const whiteLabel = {
    sessId: '10bc8e76553ea8c9502071eea81b7abd',
    city: getCity(),
    cityCode: getCityCode(),
    host: isLocalhost(true) ? 'https://tmpwpm.book24.ru/api/v1/baskets/' : 'https://api.book24.ru/api/v1/baskets/',
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-Oauth-Client-Id': isLocalhost(true) ? `f6b54d95-04cd-45eb-9c36-f645157c9bba` : 'b68cefea-3500-4219-9679-24f8c2b461e6',
    },
    basketId: localStorage.getItem('BASKET_ID'),
    promocodeGetParams: url.hash?.split('?')[1]?.split('&') || url.search.slice(1).split('&'),

    getBookData(products, callback, eventName) {
        basketMindbox = [];
        basketItemsCommonNew = [];

        Object.values(products)?.forEach((item, index) => {
            const itemProduct = item?.product;

            if (itemProduct) {
                basketItemsCommon.push({
                    name: itemProduct?.name,
                    id: itemProduct?.nomcode,
                    category: itemProduct?.category?.name,
                    brand: itemProduct?.publishingHouse?.name,
                    price: item?.amount?.total,
                    quantity: item?.quantity,
                });
            }

            if (itemProduct) {
                const arrAuthor = itemProduct?.author?.map((itemAuthor) => itemAuthor?.name);

                basketItemsCommonNew.push(
                    {
                        'item_name': itemProduct?.name,
                        'item_id': itemProduct?.nomcode,
                        'discount': itemProduct?.amount?.total - itemProduct?.amount.discounted,
                        'price': Number(itemProduct?.amount?.total),
                        'index': index + 1,
                        'quantity': item?.quantity,
                        'item_brand': itemProduct?.publishingHouse?.name,
                        'item_category': itemProduct?.category?.parentCategory[0]?.name,
                        'item_category2': itemProduct?.category?.name,
                        'item_category3': arrAuthor?.join(', '),
                        'item_category4': 'Бумажная',
                    },
                );

                if (!item?.isDeleted || eventName === 'restore-product' || eventName === 'restore-products') {
                    basketMindbox.push({
                        'nomcode': itemProduct?.nomcode,
                        'quantity': item?.quantity,
                        'price': itemProduct?.amount.discounted,
                    });
                }
            }
        });

        callback();
    },

    getDeletedBookData(deletedProduct, callback) {
        basketItemsDeleted = [];
        basketMindbox = [];

        Object.values(deletedProduct)?.forEach((item) => {
            const itemProduct = item?.product;
            const arrAuthor = itemProduct?.author?.map((itemAuthor) => itemAuthor?.name);

            if (itemProduct) {
                if (item?.isDeleted) {
                    basketItemsDeleted.push(
                        {
                            'item_name': itemProduct?.name,
                            'item_id': itemProduct?.nomcode,
                            'discount': item?.amount?.total - itemProduct?.amount.discounted,
                            'price': Number(item?.amount?.total),
                            'index': 1,
                            'quantity': item?.quantity,
                            'item_brand': itemProduct?.publishingHouse?.name,
                            'item_category': itemProduct?.category?.parentCategory[0]?.name,
                            'item_category2': itemProduct?.category?.name,
                            'item_category3': arrAuthor?.join(', '),
                            'item_category4': 'Бумажная',
                        },
                    );
                } else {
                    basketMindbox.push({
                        'nomcode': itemProduct?.nomcode,
                        'quantity': item?.quantity,
                        'price': itemProduct?.amount.discounted,
                    });
                }
            }
        });

        callback();
    },

    sendCartEvent(data = {}) {
        const salesBookBasket = $('.sales-book_basket');
        const dataPayload = data?.payload;
        const dataProducts = dataPayload?.basketItem ? dataPayload?.basketItem : dataPayload?.basket?.basketItem;
        const totalPrice = dataPayload?.amount?.discounted ? dataPayload?.amount?.discounted : dataPayload?.basket?.amount?.discounted;
        const booksViewed = JSON.parse(localStorage.getItem('booksViewed'));

        promocode = dataPayload?.basketPromoCode?.code ? data?.payload?.basketPromoCode?.code : dataPayload?.coupon;
        console.log('whiteLabel.sendCartEvent() data =', data);
        $.post('/ajax/whitelabel/cart/', data);

        if (typeof data?.eventName === 'string') {
            switch (data.eventName) {
            case 'view-basket':
                this.getBookData(dataProducts, () => {
                    eventSender.checkoutBtnGA4(totalPrice, promocode, basketItemsCommonNew);
                });

                // Формирование массива номкодов для блока "Вы смотрели".
                // Проверка есть ли в текущем массиве книги, уже добавленные в корзину. Если есть, то удаляем их из массива.
                // После готовый массив добавляем в новый localStorage "booksViewedNoBasket"
                localStorage.removeItem('booksViewedNoBasket');

                if (booksViewed && getCookie('EKSMO_BOOKS_VIEWED') === 'Y') {
                    Object.keys(basketItemsCommonNew).forEach((i) => {
                        const itemBookBasket = basketItemsCommonNew[i].item_id;

                        booksViewed.forEach((itemNomcodeBookViewedLocalStorage, index) => {
                            if (itemBookBasket === itemNomcodeBookViewedLocalStorage) {
                                booksViewed.splice(index, 1);
                            }
                        });
                    });

                    localStorage.setItem('booksViewedNoBasket', JSON.stringify(booksViewed));
                }
                //

                // Блок акций
                if (salesBookBasket.length && basketItemsCommonNew.length) {
                    if (getW() < 1025) {
                        salesBookBasket.closest('.basket-desktop-layout').removeClass('basket-desktop-layout');
                    }

                    salesBookBasket.removeClass('sales-book_hide');
                }
                //

                // Установка лайков в корзине
                eventSender.sendBooksInBasketMD(basketMindbox).then((dataLike) => {
                    setBooksLike(dataLike);
                });

                $('.skeleton').remove();
                break;
            case 'add-to-basket':
                $body.trigger('addBookBasket.eksmo', [dataPayload]);
                break;
            case 'city-select-open':
                break;
            case 'city-select-close':
                this.closeCitiesPopup();
                break;
            case 'user-register':
                break;
            case 'checkout-init':
                this.getBookData(dataProducts, () => {
                    eventSender.beginCheckoutAuthGA4(totalPrice, promocode, basketItemsCommonNew);
                });

                break;
            case 'checkout-redirect':
                eventSender.beginCheckoutGA4();
                // js функция, добавляемая на бэке, для проверки юзера на авторизацию (если не авторизован, то отправляем его на форму входа)
                checkAuthorisation(true);
                break;
            case 'order-save':
                this.getBookData(dataProducts, () => {
                    eventSender.orderSaveGA4(
                        dataProducts[0]?.product?.isPreOrder ? 'pre_order' : 'in_stock',
                        // если предзаказ, то берётся поле для единственной (первой в массиве) книги предзаказа
                        totalPrice || dataProducts[0]?.product?.amount?.discounted,
                        dataPayload?.orderId,
                        promocode || dataPayload?.basket?.basketPromoCode,
                        dataPayload?.deliveryGroup,
                        dataPayload?.summary?.deliveryPrice,
                        PAYMENT_TYPES[dataPayload?.paySystemId]?.title,
                        dataPayload?.useLoyalty,
                        dataPayload?.loyaltyAmount,
                        basketItemsCommonNew,
                    );
                });

                break;
            case 'remove-all-products':
                this.getBookData(dataProducts, () => {
                    eventSender.sendBooksInBasketMD([]);
                    eventSender.removeFromCartGA4(totalPrice, basketItemsCommonNew);
                });

                window.mindbox('async', {
                    operation: 'Website.ClearCart',
                    data: {},
                });

                break;
            case 'remove-product':
                this.getDeletedBookData(dataProducts, () => {
                    eventSender.removeFromCartGA4(totalPrice, basketItemsDeleted);
                    eventSender.sendBooksInBasketMD(basketMindbox); // Отправка в mindbox инфы о книгах в корзине
                });

                break;
            case 'change-quantity':
                this.getBookData(dataProducts, () => {
                    eventSender.sendBooksInBasketMD(basketMindbox); // Отправка в mindbox инфы о книгах в корзине
                });

                break;
            case 'restore-product':
                this.getBookData(dataProducts, () => {
                    // Установка лайков в корзине и отправка в mindbox инфы о книгах в корзине
                    eventSender.sendBooksInBasketMD(basketMindbox).then((dataLike) => {
                        setBooksLike(dataLike);
                    });
                }, 'restore-product');

                break;
            case 'restore-products':
                this.getBookData(dataProducts, () => {
                    // Установка лайков в корзине и отправка в mindbox инфы о книгах в корзине
                    eventSender.sendBooksInBasketMD(basketMindbox).then((dataLike) => {
                        setBooksLike(dataLike);
                    });
                }, 'restore-products');

                break;
            case 'cancel-order':
                eventSender.cancelOrderGA4(dataPayload);
                break;
            case 'book24ChangeLocation':
                this.changeCity(dataPayload?.cityName);
                break;
            case 'get-basket':
                $body.trigger('getBookBasket.eksmo', [dataPayload]);
                break;
            default:
                break;
            }
        }
    },

    changeCity(city) {
        $('.m-menu').trigger('eksmoMMenuClose');

        setTimeout(() => {
            this.city = isNoEmptyString(city) ? city : getCity();
            this.cityCode = getCityCode();
            $body.trigger('changeCity.wl');
        });
    },

    showCitiesPopup() {
        if (getW() < eksmoBP.mdMin) {
            $('.cheader__burger.js-btn-m-menu').click();
        } else {
            const $cheader = $('.cheader');

            if ($cheader.hasClass('cheader_fixed')) {
                isCheaderWasFixed = true;
                $cheader.removeClass('cheader_fixed');
            }

            $('[data-book24-widget-location] .b24-btn._gray').click();
        }
    },

    closeCitiesPopup() {
        if (isCheaderWasFixed) {
            $('.cheader').addClass('cheader_fixed');
            isCheaderWasFixed = false;
        }
    },

    applyPromocode(promoCode) {
        fetch(`${this.host}${this.basketId}/promo-code/?include=basketPromoCode&SESSID=1`, {
            method: 'POST',
            credentials: 'include',
            headers: this.headers,
            body: JSON.stringify({ promoCode: decodeURI(promoCode) }),
        }).catch(() => console.log('error'));
    },

    deleteBook(id) {
        return fetch(`${this.host}${this.basketId}/item/${id}/?SESSID=1`, {
            method: 'DELETE',
            credentials: 'include',
            headers: this.headers,
        })
            .then((data) => {
                if (data.ok) {
                    return Promise.resolve(data.json());
                }

                return '';
            })
            .then((data) => {
                if (data.success) {
                    return fetch(`${this.host}${this.basketId}/?mode=basket&giftStrategy=addFirstPotential&include=basketItem.product&SESSID=1`, {
                        method: 'GET',
                        credentials: 'include',
                        headers: this.headers,
                    })
                        .then((dataGetBasket) => {
                            if (dataGetBasket.ok) {
                                return Promise.resolve(dataGetBasket.json());
                            }

                            return '';
                        });
                }

                return Promise.reject();
            }).catch(() => console.log('error'));
    },

    getLink(itemsBook24, nomcodes, fnCycleBefore, fnCycleAfter) {
        window.settingsWl.productLinkMethod(nomcodes).then((itemsEksmo) => {
            itemsBook24.forEach((itemBook24) => {
                itemsEksmo.forEach((itemEksmo) => {
                    if (itemEksmo.nomcode === itemBook24.nomcode) {
                        fnCycleBefore(itemBook24, itemEksmo.url);
                    }
                });

                fnCycleAfter();
            });
        });
    },
};

if (whiteLabel.promocodeGetParams?.length) {
    const arrGetParams = [];

    whiteLabel.promocodeGetParams.forEach((item) => {
        const keyValue = item.split('=');

        arrGetParams[keyValue[0]] = keyValue[1];
    });

    if (arrGetParams.utm_medium === 'web-push') {
        whiteLabel.applyPromocode(arrGetParams.utm_content);
    }
}