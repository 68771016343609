import { eventSender } from 'Lib/event-sender/event-sender';
import { getW } from 'Lib/w/w';
import { speed } from 'Lib/speed/speed';
import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import 'imports-loader?wrapper=window!jquery-throttle-debounce'; // eslint-disable-line import/no-extraneous-dependencies, import/no-webpack-loader-syntax
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const $window = $(window);
const $body = $('body');
const sForm = '.mail-form__form';
const $books = $('.books');
const $book = $books.find('.books__item');
const sBookHelper = '.books__item-helper';
let oldScrollTop = $window.height();
const cMenu2Visible = 'mail-form_main2_visible';
const $formNews = $('.mail-form_novelties, .mail-form_gift-book-novelties');

function setMailFormNews() {
    const form = $formNews.first().remove();
    const width = getW();
    let qtyAfter = 15;

    if (width >= 614 && width <= 839) {
        qtyAfter = 16;
    }

    if ($book.length < qtyAfter) {
        $books.after(form);
    } else {
        $book.eq(qtyAfter - 1).next(sBookHelper).after(form);
    }
}

function viewJFixArticleForm() {
    const $form = $('.mail-form_j-fix-article');

    if ($form.length) {
        const $articleCont = $form.closest('.container_article');

        if ($articleCont.length) {
            const cFixActive = 'mail-form_j-fix-article-active';
            const sT = $window.scrollTop();
            const isView = (((sT + $window.height() <= $articleCont.offset().top + $articleCont.outerHeight())) && sT < oldScrollTop);

            $form.toggleClass(cFixActive, isView);

            if (!isView) {
                $form.find('input').blur();
            }
        }
    }
}

onScreen($('.mail-form_uni, .mail-form__uni-pic, .mail-form_pic'), loadSrcOrBg);

onScreen($('.mail-form_main2'), ($el) => {
    $el.addClass(cMenu2Visible);
});

viewJFixArticleForm();

if ($formNews.length) {
    setMailFormNews();
    $body.on('eksmoResizeWidth', setMailFormNews);
}

$window.on('load', () => {
    $window.on('scroll', $.throttle(speed * 2, () => {
        viewJFixArticleForm();
        oldScrollTop = $window.scrollTop();
    }));
});

$body.on('eksmoThisAjaxFormSuccess', sForm, function () {
    const $thisForm = $(this);
    const vendor = $thisForm.find('input[name=vendor]').first().val();

    if ($thisForm.closest('.mail-form_book').length || $thisForm.closest('.mail-form_book2020').length) {
        eventSender.customDL({
            event: 'eksmo',
            eventAction: 'engagement',
            eventCategory: 'Узнать о поступлении книги',
            eventLabel: $('.book-page__card-title').first().text().trim().replace('Электронная книга ', '').replace('Аудиокнига ', '').replace(/['"«»]/g, ''),
        });
    } else if (isNoEmptyString(vendor)) {
        eventSender.eventSubscribe(vendor);
    }
});