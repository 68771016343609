import { isLocalhost } from 'Lib/is-localhost/is-localhost';

const VKID = window.VKIDSDK;
const host = isLocalhost(true) ? 'https://dev.eksmo.ru/' : 'https://eksmo.ru/';

if (VKID?.OneTap) {
    const oneTapButtonAuthPopup = new VKID.OneTap();
    const oneTapButtonRegPopup = new VKID.OneTap();
    const oneTapButtonAuth = new VKID.OneTap();
    const oneTapButtonReg = new VKID.OneTap();
    const $body = $('body');

    VKID.Config.set({
        app: 51763531, // Тут нужно подставить ID своего приложения.
        redirectUrl: `${host}ajax/auth/login/`, // Адрес для перенаправления.

        appSettings: {
            agreements: '',
            promo: '',
            vkc_behavior: '',
            vkc_auth_action: '',
            vkc_brand: '',
            vkc_display_mode: '',
        },
    });

    // eslint-disable-next-line no-inner-declarations
    function insertOneTapButton(oneTapButton, cls) {
        const elCls = document.querySelector(cls);

        if (oneTapButton && elCls) {
            oneTapButton.render({
                container: elCls,
                showAlternativeLogin: false,
                showAgreements: false,
                displayMode: 'default',
                langId: 0,
                buttonSkin: 'primary',
                styles: {
                    borderRadius: 100,
                    height: 42,
                },
            });
        }
    }

    $body.on('showAuth.eksmo', () => insertOneTapButton(oneTapButtonAuthPopup, '.fancybox-content.auth-form_auth .form .form__item-vk'));
    $body.on('showReg.eksmo', () => insertOneTapButton(oneTapButtonRegPopup, '.fancybox-content.auth-form_reg .form .form__item-vk'));
    insertOneTapButton(oneTapButtonAuth, '.auth-form_auth .form .form__item-vk');
    insertOneTapButton(oneTapButtonReg, '.auth-form_reg .form .form__item-vk');
}