import fastdom from 'fastdom';

const $body = $('body');
const sAuth = '.auth';
const sDropDown = '.auth__drop-down';
const cDropDownActive = 'auth__drop-down_active';
const $header = $('.cheader').first();

function setTriangleOffset() {
    const $auth = $(sAuth);

    if ($auth.length) {
        fastdom.measure(() => {
            const right = parseInt(($auth.width() / 2) - 10);

            fastdom.mutate(() => {
                $('.auth__drop-down-triangle').css({ 'transform': 'none', 'left': 'auto', 'right': right });
            });
        });
    }
}

$body.on('mouseenter', sAuth, () => {
    $(sDropDown).addClass(cDropDownActive);
});

$body.on('mouseleave', sAuth, () => {
    $(sDropDown).removeClass(cDropDownActive);
});

setTriangleOffset();
$header.on('eksmoHeaderFixed', setTriangleOffset);
$body.on('eksmoResizeWidth', setTriangleOffset);

$body.on('bitrixDynamicCompositeSuccess.eksmo', () => {
    setTimeout(() => {
        setTriangleOffset();
    }, 10);
});