import { formToObject, formClearInputs } from 'Lib/o-form-tools/o-form-tools';
import { onScreen } from 'Lib/on-screen/on-screen';
import { eventSender } from 'Lib/event-sender/event-sender';
import { sendRegDL } from 'Lib/show-reg/show-reg';

// Авторизация, Регистрация, восстановление пароля
const $b = $('body');
const selectorLinkAuthForms = '.js-link-auth-forms';
const parent = '.js-auth-forms-content';
const selectorLinkShowPassword = `${parent} .js-link-show-password`;
const selectorSubmit = 'input[type=submit]';
const selectorCheckboxNewsSubscribe = `${parent} #js-auth-forms_checkbox_news-subscribe`;
const selectorInputReq = `${parent} .js-input-req`;
const selectorInputSuccessIcon = `${parent} .js-input-success-icon`;
const selectorInputErrorIcon = `${parent} .js-input-error-icon`;
const selectorRegSubmit = `${parent} .js-reg-submit`;
const classActive = 'js-active';
const classInputError = 'js-error';
const selectorResponce = '.responce';
const $immediateAuthCall = $('.js-immediate-auth-call');

function sendAuthorizeDL() {
    onScreen($('.auth-form_auth'), () => {
        eventSender.customDL({
            event: 'eksmo',
            eventAction: 'authorize',
            eventCategory: 'Плашка с авторизацией',
            eventLabel: window.location.href,
        });
    });
}

export function showAuthFancy(href) {
    if (typeof href === 'string' && href.length) {
        $.fancybox.close();

        $.fancybox.open({
            baseClass: 'auth-forms',
            type: 'ajax',
            src: href,
            touch: false,
            afterLoad: (instance, current) => {
                $b.trigger('eksmoFancyboxAfterLoad', [instance, current]);
                sendAuthorizeDL();
                $b.trigger('showAuth.eksmo');
            },
        });
    }

    return false;
}

sendAuthorizeDL();
sendRegDL();

$b.on('click', '.auth-form__btn-vk', () => {
    eventSender.customDL({
        event: 'eksmo',
        eventAction: 'authorize',
        eventCategory: 'Вход - VK',
        eventLabel: window.location.href,
    });
});

$b.on('click', '.auth-form__link-mail', () => {
    eventSender.customDL({
        event: 'eksmo',
        eventAction: 'authorize',
        eventCategory: 'Вход - e-mail',
        eventLabel: window.location.href,
    });
});

$b.on('submit', `${parent} form`, function (e) {
    const frm = $(this);
    const submit = $(frm).find(selectorSubmit);
    const href = $(frm).attr('action');
    const o = formToObject(parent);
    let sWarning;
    const $resp = $(frm).find(selectorResponce);

    submit.attr('value', 'Загрузка...');

    if (submit.attr('data-loaded') === 'Y') {
        return false;
    }

    submit.attr('data-loaded', 'Y');

    if ($(frm).attr('id') === 'js-auth-form') {
        if (!o?.USER_LOGIN?.trim().length && !o?.USER_PASSWORD?.trim().length) {
            sWarning = 'Вы не ввели Email и пароль.';
        } else if (!o?.USER_LOGIN?.trim().length) {
            sWarning = 'Вы ввели пароль, но не ввели Email.';
        } else if (!o?.USER_PASSWORD?.trim().length) {
            sWarning = 'Вы ввели Email, но не ввели пароль.';
        }
    }

    if (sWarning) {
        $resp.html(`<div class="alert-a warning">
            <svg><use xlink:href="#icon-warning"></use></svg>
            ${sWarning}
            </div>`);

        submit.attr('data-loaded', 'N');
        submit.attr('value', submit.attr('data-txt'));

        return false;
    }

    o.ajax = 'Y';

    $.post(href, o, (data) => {
        $resp.html(data);
        submit.attr('value', submit.attr('data-txt'));
        submit.attr('data-loaded', 'N');

        if ($(frm).attr('id') === 'js-registration-form' && data.indexOf('success') > 0) {
            formClearInputs('#js-registration-form');

            setTimeout(() => {
                $.fancybox.close();
            }, 5000);
        }
    });

    e.stopPropagation();

    return false;
});

$b.on('click', selectorLinkAuthForms, function (e) {
    e.preventDefault();

    const $th = $(this);

    showAuthFancy($th.attr('href') || $th.attr('data-href'));
});

$b.on('click', selectorLinkShowPassword, function () {
    if (!$(this).hasClass(classActive)) {
        $(this).addClass(classActive);
        $(parent).find('#password, #password2').attr('type', 'text');
    } else {
        $(this).removeClass(classActive);
        $(parent).find('#password, #password2').attr('type', 'password');
    }
});

$b.on('change', `${selectorCheckboxNewsSubscribe} input[type=checkbox]`, function () {
    if ($(this).is(':checked')) {
        $(this).parents(selectorCheckboxNewsSubscribe).addClass(classActive);
    } else {
        $(this).parents(selectorCheckboxNewsSubscribe).removeClass(classActive);
    }
});

$b.on('change keyup paste focus blur', selectorInputReq, function () {
    const attrName = $(this).attr('data-name');
    const val = $(this).val();
    const $successIcon = $(this).siblings(selectorInputSuccessIcon).first();
    const $errorIcon = $(this).siblings(selectorInputErrorIcon).first();
    const minPassLength = 6;
    let qtyInputTrue = 0;

    $(this).removeClass(classInputError);
    $errorIcon.removeClass(classActive);

    if ($(selectorRegSubmit).length) {
        if (attrName === 'email') {
            const patternEmail = /^([a-z0-9_.-])+@[a-z0-9-]+\.([a-z0-9]{2,32}\.)?[a-z0-9]{2,32}$/i;

            if (patternEmail.test(val)) {
                $successIcon.addClass(classActive);
            } else {
                $successIcon.removeClass(classActive);
            }
        } else if (attrName === 'password') {
            const secondPass = $(selectorInputReq).filter('#password2');

            if (secondPass.length) {
                if (secondPass.val().length >= minPassLength && secondPass.val() === val) {
                    secondPass.siblings(selectorInputSuccessIcon).first().addClass(classActive);
                } else {
                    secondPass.siblings(selectorInputSuccessIcon).first().removeClass(classActive);
                }
            }

            if (val.length >= minPassLength) {
                $successIcon.addClass(classActive);
            } else {
                $successIcon.removeClass(classActive);
            }
        } else if (attrName === 'password2') {
            const firstPassVal = $(selectorInputReq).filter('#password').val();

            if (val.length >= minPassLength && val === firstPassVal) {
                $successIcon.addClass(classActive);
            } else {
                $successIcon.removeClass(classActive);
            }
        }

        $(selectorInputReq).each(function () {
            if ($(this).siblings(selectorInputSuccessIcon).first().hasClass(classActive)) {
                qtyInputTrue++;
            }
        });

        if (qtyInputTrue === $(selectorInputReq).length) {
            $(selectorRegSubmit).removeAttr('disabled');
        } else {
            $(selectorRegSubmit).attr('disabled', 'disabled');
        }
    }
});

if ($immediateAuthCall.length) {
    showAuthFancy($immediateAuthCall.attr('href') || $immediateAuthCall.attr('data-href'));
}