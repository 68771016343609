import { whiteLabel } from 'Lib/white-label/white-label';
import { eksmoBP } from 'Lib/bp/bp';
import { getW } from 'Lib/w/w';
import { eventSender } from 'Lib/event-sender/event-sender';
import { inclination } from 'Lib/inclination/inclination';

const $body = $('body');
const $cart = $('.cart_popup');
let dataUpdated = true;
let startLoad = false;
let totalPrice = 0;
let count = 0;
let items = [];
let nomdcodes = [];

// Записываем полученные данные в 'items' и 'nomdcodes'
function updateDataCart(data, productItem, type) {
    items = [];
    nomdcodes = [];

    if (data?.length) {
        data.forEach((item) => {
            const product = item[productItem];

            if (item.type === type) {
                items.push({
                    'nomcode': product?.nomcode,
                    'name': product?.name,
                    'price': product?.amount?.discounted,
                    'oldPrice': product?.amount?.total,
                    'productId': item?.productId ? Number(item?.productId) : Number(item?.id),
                    'idPosition': item?.id,
                });
            }

            if (type === 'product') {
                const lastItem = items[items.length - 1];

                data.forEach((itemInner) => {
                    if (Number(itemInner?.attributes?.productId) === lastItem?.productId && itemInner.type === 'cartItem') {
                        lastItem.idPosition = itemInner?.id;
                    }
                });
            }

            dataUpdated = true;
            nomdcodes.push(product?.nomcode);
        });
    }
}
//

// Получаем данные о книгах в корзине по событию бука 'getBookBasket.eksmo'
$body.on('getBookBasket.eksmo', (e, data) => {
    if (getW() > eksmoBP.smMax) {
        totalPrice = data?.amount?.discounted;
        count = data?.basketItem?.length;
        updateDataCart(data?.basketItem, 'product', 'basketItem');

        if (!startLoad) {
            startLoad = true;

            $cart.find('.cart__popup').append(`
                <div class="cart__popup-box">
                    <div class="cart__popup-head">
                        <div class="cart__popup-head-title">
                            <span class="cart__popup-head-title-span">Корзина, </span><span class="cart__popup-head-title-count"></span>
                        </div>

                        <div class="cart__popup-head-close"></div>
                    </div>

                    <div class="cart__popup-body">
                        <div class="cart__popup-body-loader loader loader_center loader_orange">
                            <div class="loader__cont">
                                <svg class="loader__svg"><use xlink:href="#loader"></use></svg>
                            </div>
                        </div>
                    </div>

                    <div class="cart__popup-footer">
                        <div class="cart__popup-footer-result">
                            <div class="cart__popup-result-item">Подытог:</div>
                            <div class="cart__popup-result-item cart__popup-result-item_price"></div>
                        </div>

                        <div class="cart__popup__footer-btn-box">
                            <a href="/cart/#/personal/cart" class="btn btn_bg-none btn_border btn_small">В корзину</a>
                            <a href="/cart/#/personal/order/make" class="btn btn_orange btn_small">Оформить заказ</a>
                        </div>
                    </div>
                </div>
            `);
        }
    }
});
//

// При наведении формируем список книг в корзине
$cart.on('mouseenter', () => {
    if (items.length) {
        $cart.addClass('cart_active');

        if (dataUpdated) {
            let itemsHtml = '';

            whiteLabel.getLink(items, nomdcodes, (item, url) => {
                itemsHtml += `
                    <div class="cart__popup-body-item" data-id="${item.idPosition}" data-nomcode="${item.nomcode}" data-old-price="${Math.round(item.oldPrice)}" data-price="${Math.round(item.price)}">
                        <a class="cart__popup-body-item-img-box" href="${url}">
                            <img class="cart__popup-body-item-img" src="https://cdn.eksmo.ru/v2/${item.nomcode}/COVER/cover1__w410.webp" alt="${item.name}">
                        </a>

                        <div class="cart__popup-body-item-content">
                            <div class="cart__popup-body-item-main">
                                <a class="cart__popup-body-item-title" href="${url}">${item.name}</a>
                                <a class="cart__popup-body-item-price" href="${url}">${Math.round(item.price)} ₽${item.oldPrice ? ` <span class="cart__popup-body-item-price-cur">${Math.round(item.oldPrice)}<span class="cart__popup-body-item-price-currency"> ₽</span></span>` : ''}</a>
                            </div>
                            <div class="cart__popup-body-item-delete-box">
                                <div class="cart__popup-body-item-delete-item">
                                    <svg class="cart__popup-body-item-delete"><use xlink:href="#basket-delete"></use></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                `;
            }, () => {
                dataUpdated = false;
                $cart.find('.cart__popup-body').html('');
                $cart.find('.cart__popup-body').append(itemsHtml);
                $cart.find('.cart__popup-head-title-count').text(`${count}  ${inclination(count, ['товар', 'товара', 'товаров'])}`);
                $cart.find('.cart__popup-result-item_price').text(`${Math.round(totalPrice)} ₽`);
            });
        }
    }
});

$cart.on('mouseleave', () => {
    $cart.removeClass('cart_active');
});
//

// Удаляем книгу по нажатию на иконку удаления
$body.on('click', '.cart__popup-body-item-delete-item', function () {
    const $parent = $(this).closest('.cart__popup-body-item');
    const id = $parent.attr('data-id');
    const oldPrice = Number($parent.attr('data-old-price'));
    const objDelete = {
        'item_name': $parent.find('.cart__popup-body-item-title').text(),
        'item_id': $parent.attr('data-nomcode'),
        'discount': oldPrice - Number($parent.attr('data-price')),
        'price': oldPrice,
        'index': 1,
        'quantity': 1,
        'item_category4': 'Бумажная',
    };

    $parent.addClass('cart__popup-body-item_inactive');

    whiteLabel.deleteBook(id).then((data) => {
        const dataIncluded = data?.data?.included;
        const counter = $('.book24-widget-basket-mini__counter');

        if (dataIncluded?.length) {
            const dataInfo = data?.data?.data?.attributes;

            updateDataCart(data?.data?.included, 'attributes', 'product');
            count = dataInfo?.quantity;
            totalPrice = Math.round(dataInfo?.amount?.discounted);
            counter.text(count);
            $parent.remove();
        } else {
            items = [];
            nomdcodes = [];
            counter.text('0');
            totalPrice = 0;
            $cart.removeClass('cart_active');
        }

        eventSender.removeFromCartGA4(totalPrice, objDelete);
    });
});
//