import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const sParent = '.pagenav-ajax';
const sCont = '.pagenav-ajax__cont';
const sNav = '.pagenav-ajax__nav';
const sBtn = '.pagenav-ajax__btn';
const loaderHTML = '<div class="pagenav-ajax__loader"><div class="loader loader_center pagenav-ajax__loader-mix"><div class="loader__cont pagenav-ajax__loader-style"><svg class="loader__svg"><use xlink:href="#loader"></use></svg></div></div></div>';
const cBtnWithLoader = 'pagenav-ajax__btn_loader';
const cBtnReplacer = 'pagenav-ajax__btn_replacer';
const cBtnNoHistory = 'pagenav-ajax__btn_no-history';

function nextPage($b) {
    if ($b.length) {
        const $p = $b.closest(sParent);
        let href = $b.attr('href');
        const dataHref = $b.attr('data-href');

        if (!href && dataHref) {
            href = dataHref;
        }

        if ($p.length && isNoEmptyString(href)) {
            const $c = $p.find(sCont).first();
            const $n = $p.find(sNav);
            const dataUniq = $p.attr('data-uniq');

            if ($c.length && $n.length) {
                if ($b.hasClass(cBtnWithLoader)) {
                    $b.replaceWith(loaderHTML);
                }

                $.get(href, { 'ajax_pagination': 'Y' }, (data) => {
                    let additionalSelector = '';
                    const $data = $('<html />').html(data);
                    let dpNumStart = 0;
                    const $dpBookItemLast = $c.find('.books__item[data-pos]').last();

                    if ($dpBookItemLast.length) {
                        let dpNewNumStart = $dpBookItemLast.attr('data-pos');

                        if (typeof dpNewNumStart === 'string') {
                            dpNewNumStart = parseInt(dpNewNumStart);

                            if (!Number.isNaN(dpNewNumStart)) {
                                dpNumStart = dpNewNumStart + 1;
                            }
                        }
                    }

                    if (isNoEmptyString(dataUniq)) {
                        additionalSelector = `${sParent}[data-uniq=${dataUniq}] `;
                    }

                    const $dC = $(additionalSelector + sCont, $data).first();
                    const $dpNewBookItem = $dC.find('.books__item[data-pos]');

                    if (dpNumStart) {
                        $dpNewBookItem.each(function () {
                            $(this).attr('data-pos', dpNumStart);
                            dpNumStart++;
                        });
                    }

                    if ($dC.length) {
                        if (!$b.hasClass(cBtnReplacer)) {
                            $c.append($dC.html());
                        } else {
                            $c.html($dC.html());
                        }

                        $n.each(function () {
                            const dataNavUniq = $(this).attr('data-uniq');
                            let navAdditionalSelector = '';

                            if (isNoEmptyString(dataNavUniq)) {
                                navAdditionalSelector = `[data-uniq=${dataNavUniq}]`;
                            }

                            const $dN = $(additionalSelector + sNav + navAdditionalSelector, $data).first();

                            if ($dN.length) {
                                $(this).html($dN.html());
                            } else {
                                $(this).html('');
                            }
                        });

                        if (window.history && !$b.hasClass(cBtnNoHistory)) {
                            const arMatch = href.match(/\/page\d+\//g);

                            if (arMatch !== null && arMatch.length) {
                                let additionalTitle = '';
                                const $title = $('title');
                                const $h1 = $('h1').first();
                                let textH1 = $h1.text();
                                const nextPageNum = arMatch[arMatch.length - 1].match(/\d+/).shift();

                                if ($h1.children().length) {
                                    textH1 = '';
                                }

                                if (nextPageNum) {
                                    additionalTitle = ` страница ${nextPageNum}`;
                                }

                                textH1 += additionalTitle;
                                window.history.replaceState({}, '', href);
                                $title.text(textH1);
                            }
                        }
                    }

                    $p.trigger('eksmoPagenavAjax');
                    setupButtonAddToBasket(window.settingsWl);
                });
            }
        }
    }
}

$('body').on('click', sBtn, function (e) {
    const $this = $(this);

    if (typeof $this.attr('href') === 'string' || typeof $this.attr('data-href') === 'string') {
        e.preventDefault();
    }

    nextPage($this);
});