function loadStyle() {
    return new Promise((resolve, reject) => {
        if (!document.getElementById('css-version-visually-impaired')) {
            const style = document.createElement('link');

            style.onload = () => resolve();
            style.onerror = () => reject();
            style.href = `${window.URLBuild}/site/common/css/version-visually-impaired.css`;
            style.type = 'text/css';
            style.rel = 'stylesheet';
            style.id = 'css-version-visually-impaired';
            document.head.insertBefore(style, document.head.firstChild);
        }
    });
}

$('body').on('eksmoOpenVisuallyImpaired', () => {
    const $versionVisuallyImpaired = $('.version-visually-impaired');

    if (!$versionVisuallyImpaired.length) {
        Promise.all([
            import('Bem/site/version-visually-impaired/menu/menu.js'),
            import('Bem/site/version-visually-impaired/functional/functional.js'),
            loadStyle(),
        ])
            .catch(() => console.error('Ошибка загрузки версии для слабовидящих'));
    } else {
        $versionVisuallyImpaired.toggleClass('version-visually-impaired_active');
    }
});