import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { speed } from 'Lib/speed/speed';
import { getUrlByInputs } from 'Lib/get-url-by-inputs/get-url-by-inputs';
import { isLocalStorageEnabled } from 'Lib/is-local-storage-enabled/is-local-storage-enabled';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';
import { eventSender } from 'Lib/event-sender/event-sender';
import './include/previous-filters';

const sBtn = '.js-burger, .js-btn-m-menu';
const $body = $('body');
const cBodyActive = 'js-m-menu-body';
let $mMenu = $('.m-menu');
const cMenuLoaded = 'm-menu_loaded';
const cMenuActive = 'm-menu_active';
const $close = $('.m-menu__close');
const $closeBtn = $('.m-menu__close-btn');
let $panel = $('.m-menu__panel');
let href = $panel.attr('data-href');
const sLink = '.m-menu__title';
const cLinkActive = 'm-menu__title_opened';
const sSubmenu = '.m-menu__submenu';
const sSubmenuCont = '.m-menu__submenu-cont';
let $btnAttr = 0;
const cMenuReload = 'm-menu_reload';
let loaderHTML = '';
const sItem = '.m-menu__s-item';
const sRadioBtn = '.m-menu__s-item-btn';
const sRadioBtnInput = '.m-menu__s-item-btn input[type=radio]';
const cRadioBtnChecked = 'm-menu__s-item-btn_checked';
const sBtnsSpoilerLink = '.m-menu__s-item-btns-item_spoiler';
const sBtnsSpoiler1 = '.m-menu__s-item-btns_1';
const sBtnsSpoiler2 = '.m-menu__s-item-btns_2';
const sFinder = '.m-menu__finder';
const sSpoiler = '.m-menu__spoiler';
const cSpoilerOpened = 'm-menu__spoiler_opened';
const $spoilerLink = $('.m-menu__spoiler-link');
const sSpoilerContainer = '.m-menu__spoiler-container';
const sSpoilerCont = '.m-menu__spoiler-cont';
const lsKey = 'EksmoCatalogMenuClosedSpoilers';
let closedSpoilersName = (isLocalStorageEnabled() && localStorage.getItem(lsKey)) ? JSON.parse(localStorage.getItem(lsKey)) : [];
const sFilterInput = '.m-menu__filter-input';
const $filterControl = $('.m-menu__s-control').first();
const cFilterControlHidden = 'm-menu__s-control_hidden';
const $filterSubmit = $('.m-menu__filter-submit').first();
const $filterReset = $('.m-menu__filter-reset').first();
let resetHref = $filterReset.attr('href');
let backUrl = '';

// FUNCTIONS
function setMenu() {
    if ((!$mMenu.hasClass(cMenuLoaded) || $mMenu.hasClass(cMenuReload))) {
        if (href?.length) {
            if (!loaderHTML.length && $panel.find('.loader').length) {
                loaderHTML = $panel.html();
            }

            $.ajax({
                url: href,
                success: (data) => {
                    setTimeout(() => {
                        $panel.html(data);
                        $mMenu.trigger('eksmoSetMMenu');
                    }, speed * 3);
                },
            });
        } else {
            $mMenu.trigger('eksmoSetMMenu');
        }

        $mMenu.addClass(cMenuLoaded);
    }
}

function showMenu() {
    const $sCont = $panel.find('.m-menu__s-cont');

    if ($sCont.length) {
        disableBodyScroll($sCont[0]);
    } else {
        disableBodyScroll($panel[0]);
    }

    $body.addClass(cBodyActive);
    $mMenu.show();

    setTimeout(() => {
        $mMenu.addClass(cMenuActive);
        setMenu();
        $mMenu.trigger('eksmoShowMMenu');
    }, 10);
}

function hideMenu() {
    $panel.scrollTop(0);
    clearAllBodyScrollLocks();
    $body.removeClass(cBodyActive);
    $mMenu.removeClass(cMenuActive);

    setTimeout(() => {
        $mMenu.hide();

        if ($mMenu.hasClass(cMenuReload)) {
            $panel.html(loaderHTML);
        }

        $mMenu.trigger('eksmoHideMMenu');
    }, speed * 2);
}

function toggleClassRadioBtn() {
    $(sRadioBtnInput).each(function () {
        const $input = $(this);

        $input.closest(sRadioBtn).toggleClass(cRadioBtnChecked, $input.is(':checked'));
    });
}

function toggleBtnsSpoiler(e) {
    if ($(e.target).closest(sBtnsSpoiler1).length) {
        $(sBtnsSpoiler1).hide();
        $(sBtnsSpoiler2).show();
    } else if ($(e.target).closest(sBtnsSpoiler2).length) {
        $(sBtnsSpoiler2).hide();
        $(sBtnsSpoiler1).show();
    }
}

function setBackUrl(e) {
    if (!e.target.checked) {
        backUrl = $(e.target).attr('data-single-back-filter-url');
    } else {
        backUrl = '';
    }
}

function setSubmitHref() {
    if ($filterSubmit.length && $filterReset.length && isNoEmptyString(resetHref)) {
        const urlGet = getUrlByInputs($mMenu.find(sFilterInput));
        const inputValues = urlGet.split('&');
        const singleInputName = inputValues.length === 1 ? inputValues[0].split('=')[0] : '';
        const cInputCheckbox = `${sFilterInput}[name="${singleInputName}"]`;

        if (isNoEmptyString(urlGet)) {
            resetHref = backUrl || resetHref;

            let newHref = resetHref + ((resetHref.includes('?')) ? '&' : '?') + urlGet;

            if (isNoEmptyString(singleInputName)) {
                const url = $(cInputCheckbox).attr('data-single-filter-url');

                if (isNoEmptyString(url)) {
                    newHref = url;
                }

                document.querySelector(cInputCheckbox).addEventListener('change', setBackUrl);
            }

            $filterSubmit.attr('href', newHref);
            $filterControl.removeClass(cFilterControlHidden);
        } else if (backUrl) {
            $filterSubmit.attr('href', backUrl);
            $filterControl.removeClass(cFilterControlHidden);
        } else {
            $filterSubmit.attr('href', resetHref);
            document.querySelector(cInputCheckbox)?.removeEventListener('change', setBackUrl);
        }
    }
}

function rememberSpoilerState(name, state) {
    const thisName = name.trim();

    if (isNoEmptyString(thisName) && typeof state === 'boolean' && isLocalStorageEnabled()) {
        if (state) {
            closedSpoilersName = closedSpoilersName.filter((item) => item !== thisName);
        } else if (!closedSpoilersName.includes(thisName)) {
            closedSpoilersName.push(thisName);
        }

        localStorage.setItem(lsKey, JSON.stringify(closedSpoilersName));
    }
}

setSubmitHref();

$body.on('click', sBtn, function () {
    const $this = $(this);

    if ($this.is('[data-m-menu]')) {
        $btnAttr = $this.attr('data-m-menu');
        $mMenu = $(`.m-menu[data-m-menu = ${$btnAttr}]`);
    } else {
        $mMenu = $('.m-menu').first();
    }

    $panel = $mMenu.find('.m-menu__panel');
    href = $panel.attr('data-href');

    if ($mMenu.hasClass(cMenuActive)) {
        hideMenu();
    } else {
        showMenu();
    }
});

$close.on('click', hideMenu);
$closeBtn.on('click', hideMenu);
$mMenu.on('eksmoMMenuClose', hideMenu);

$mMenu.on('click', sLink, function (e) {
    const $this = $(this);
    const $subMenu = $this.siblings(sSubmenu).not('.m-menu__submenu_open').first();

    if ($subMenu.length) {
        e.preventDefault();

        if (!$this.hasClass(cLinkActive)) {
            $subMenu.height($subMenu.find(sSubmenuCont).height());
            $this.addClass(cLinkActive);

            setTimeout(() => {
                $subMenu.removeAttr('style');
            }, speed * 2);
        } else {
            $subMenu.height($subMenu.find(sSubmenuCont).height());
            $subMenu.height(0);
            $this.removeClass(cLinkActive);
        }
    }
});

if (isLocalStorageEnabled()) {
    $spoilerLink.each(function () {
        const $link = $(this);

        if (closedSpoilersName.includes($link.text().trim())) {
            $link.closest(sSpoiler).removeClass(cSpoilerOpened);
        }
    });
}

$spoilerLink.on('click', function (e) {
    const $link = $(this);
    const $parent = $link.closest(sSpoiler);
    const $container = $parent.find(sSpoilerContainer);
    const $cont = $parent.find(sSpoilerCont);

    if ($container.length && $cont.length) {
        const state = !$parent.hasClass(cSpoilerOpened);

        e.preventDefault();

        if (state) {
            $container.height($cont.outerHeight(true));

            setTimeout(() => {
                $container.removeAttr('style');
                $parent.addClass(cSpoilerOpened);
            }, speed);
        } else {
            $container.height($cont.outerHeight(true));
            $container.height(0);
            $parent.removeClass(cSpoilerOpened);
        }

        rememberSpoilerState($link.text(), state);
    }
});

$mMenu.on('eksmoSetMMenu', toggleClassRadioBtn);
$mMenu.on('change', sRadioBtnInput, toggleClassRadioBtn);
$mMenu.on('click', sBtnsSpoilerLink, toggleBtnsSpoiler);
$mMenu.on('click', '.ajs', hideMenu);

$mMenu.on('eksmoClickResultItemFinder', sFinder, function (e, arrData) {
    const $searchItem = $(this).closest(sItem);
    const $template = $searchItem.find('.m-menu__item-result-finder-template').first();
    const $item = $searchItem.siblings(sItem);
    const $existingTargetInput = $item.find(`${sFilterInput}[value=${arrData.id}]`);

    if ($existingTargetInput.length) {
        $existingTargetInput.prop('checked', true);
    } else if ($template.length) {
        let htmlTemplate = $template.html();

        if (htmlTemplate.length) {
            Object.keys(arrData).forEach((propName) => {
                htmlTemplate = htmlTemplate.replace(new RegExp(`{${propName}}`, 'ig'), arrData[propName]);
            });

            htmlTemplate = htmlTemplate.replace(new RegExp(/\{(.+?)\}/, 'ig'), '');
            $searchItem.after(htmlTemplate);

            const $input = $searchItem.next(sItem).find('.checkbox').first().find('input[type=checkbox]');

            $input.prop('checked', true);
            $input.trigger('change');
            setSubmitHref();
        }
    }
});

$mMenu.on('change', sFilterInput, setSubmitHref);

$body.on('click', sLink, function () {
    const $th = $(this);

    if (!$th.closest('.m-menu__submenu').length) {
        eventSender.clickMainMenu($(this));
    }

    if ($th.closest('.m-menu__item-version-visually-impaired').length) {
        hideMenu();
        window.scrollTo(0, 0);
        $body.trigger('eksmoOpenVisuallyImpaired');
    }
});