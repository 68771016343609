import { getW } from 'Lib/w/w';
import { onScreen } from 'Lib/on-screen/on-screen';
import { speed } from 'Lib/speed/speed';
import { eventSender } from 'Lib/event-sender/event-sender';
import { eksmoBP } from 'Lib/bp/bp';
import { getObjectGetParams } from 'Lib/get-object-get-params/get-object-get-params';

const $body = $('body');
const $footer = $('.footer');
const sSpoiler = '.footer__link_cont';
const sSpoilerA = '.footer__link-a';
const sContSpoiler = '.footer__cont-spoiler';
const cSpoilerOpened = 'footer__link_cont-opened';
const url = $footer.attr('data-href');
let lastStateIsMobileScreen = isMobileScreen();

function isMobileScreen() {
    return (getW() < eksmoBP.xsMax);
}

$(window).on('load', () => {
    const getParams = getObjectGetParams($footer.attr('data-get'));

    if (typeof url === 'string' && url.length) {
        onScreen($footer, ($el) => {
            $.ajax({
                url,
                cache: false,
                timeout: 3000,
                data: getParams,
                success(data) {
                    $el.html(data);
                    $el.addClass('footer_loaded');
                    $body.trigger('footerLoaded.eksmo');
                },
            });
        }, 600);
    }
});

$footer.on('click', sSpoilerA, (e) => {
    if (isMobileScreen()) {
        e.preventDefault();
    }
});

$footer.on('click', sSpoiler, function () {
    if (isMobileScreen()) {
        const $this = $(this);
        const $contSpoiler = $this.find(sContSpoiler);
        const $cont = $this.find('.footer__cont');

        if (!$this.hasClass(cSpoilerOpened)) {
            $contSpoiler.height($cont.outerHeight(true));
            $this.addClass(cSpoilerOpened);

            setTimeout(() => {
                $contSpoiler.removeAttr('style');
            }, speed * 2);
        } else {
            $contSpoiler.height($cont.outerHeight(true));
            $contSpoiler.height(0);
            $this.removeClass(cSpoilerOpened);
        }
    }
});

$body.on('eksmoResizeWidth', () => {
    if (isMobileScreen() !== lastStateIsMobileScreen) {
        if (!isMobileScreen()) {
            $footer.find(sContSpoiler).removeAttr('style');
            $footer.find(sSpoiler).removeClass(cSpoilerOpened);
        }

        lastStateIsMobileScreen = isMobileScreen();
    }
});

onScreen($footer, () => {
    eventSender.customDL({
        event: 'eksmo',
        eventAction: 'view',
        eventCategory: $('title').text().trim(),
        eventLabel: 'Подвал сайта',
    });
}, 0, 0, 0.4);

$body.on('click', '.footer__s-link, .footer__link, .footer__link-a, .footer__faq-btn', function () {
    const $th = $(this);

    if (!$th.hasClass('footer__link') || ($th.hasClass('footer__link') && !$th.find('.footer__s-link').length)) {
        eventSender.customDL({
            event: 'eksmo',
            eventAction: 'click',
            eventCategory: $('title').text().trim(),
            eventLabel: `Подвал сайта - ${$th.text().trim()}`,
        });
    }
});