$('[data-fancybox]').fancybox({
    infobar: false,
    toolbar: false,
    buttons: [
        // "zoom",
        // "share",
        // "slideShow",
        // "fullScreen",
        // "download",
        // "thumbs",
        // "close"
    ],
    spinnerTpl: `<div class="loader loader_center">
                    <div class="loader__cont">
                        <svg class="loader__svg"><use xlink:href="#loader"></use></svg>
                    </div>
                </div>`,
    lang: 'ru',
    i18n: {
        ru: {
            CLOSE: 'Закрыть',
            NEXT: 'Вперед',
            PREV: 'Назад',
        },
    },
    btnTpl: {
        close: '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}"></button>',
        arrowLeft: '<a data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}" href="javascript:;"></a>',
        arrowRight: '<a data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}" href="javascript:;"></a>',
        smallBtn: '<button data-fancybox-close class="fancybox-close-small" title="{{CLOSE}}"></button>',
    },
    touch: false,
    smallBtn: true,
    autoFocus: true,
    speed: 200,
    afterLoad(instance, current) {
        $('body').trigger('eksmoFancyboxAfterLoad', [instance, current]);
    },
});