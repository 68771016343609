import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';

const parent = document.querySelector('.arrow-up');
let lastScroll = 0;
let timeout;

function scrollUp() {
    const top = Math.max(document.body.scrollTop, document.documentElement.scrollTop);

    if (top > 0) {
        window.scrollBy(0, -100);
        timeout = setTimeout(scrollUp, 20);
    } else {
        clearTimeout(timeout);
    }

    return false;
}

parent?.addEventListener('click', scrollUp);

window.onscroll = function () {
    if (getW() < eksmoBP.smMax) {
        if (window.scrollY > lastScroll) {
            parent?.classList.remove('arrow-up_active');
        } else {
            parent?.classList.add('arrow-up_active');
        }

        lastScroll = window.scrollY;
    } else if (window.pageYOffset > 900) {
        parent?.classList.add('arrow-up_active');
    } else {
        parent?.classList.remove('arrow-up_active');
    }
};