import { Popup } from 'Lib/popup/popup';
import 'Vendor/owlcarousel/owl.carousel.min';
import { speed } from 'Lib/speed/speed';
import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import { Swipe } from 'Lib/swipe/swipe';

const cSliderOn = 'owl-carousel';
const elHelper = document.querySelector('.helper');
const url = elHelper?.getAttribute('data-href');
const elHelperBtn = document.querySelector('.helper__btn');

function switchSlide(whichWay) {
    const elCurrentActive = document.querySelector('.helper__item_active');
    const elCurrentActiveLine = document.querySelector('.helper__main-load_active');

    elCurrentActive?.classList.remove('helper__item_active');
    elCurrentActiveLine?.classList.remove('helper__main-load_active');

    if (whichWay === 'prev') {
        if (elCurrentActive?.previousElementSibling) {
            elCurrentActive?.previousElementSibling.classList.add('helper__item_active');
            elCurrentActiveLine?.previousElementSibling.classList.add('helper__main-load_active');
        } else {
            document.querySelector('.helper__box')?.lastElementChild.classList.add('helper__item_active');
            document.querySelector('.helper__main-load-box')?.lastElementChild.classList.add('helper__main-load_active');
        }
    } else if (elCurrentActive?.nextElementSibling) {
        elCurrentActive?.nextElementSibling.classList.add('helper__item_active');
        elCurrentActiveLine?.nextElementSibling.classList.add('helper__main-load_active');
    } else {
        document.querySelector('.helper__item')?.classList.add('helper__item_active');
        document.querySelector('.helper__main-load')?.classList.add('helper__main-load_active');
    }
}

function initHelper() {
    if (getW() > eksmoBP.mdMin) {
        $('.helper__box').trigger('destroy.owl.carousel')
            .removeClass(cSliderOn)
            .addClass(cSliderOn)
            .owlCarousel({
                items: 1,
                margin: 0,
                slideBy: 1,
                smartSpeed: speed,
                dots: false,
                nav: true,
                navClass: [
                    'helper__slider-prev arrow arrow_prev arrow_shadow',
                    'helper__slider-next arrow arrow_next arrow_shadow',
                ],
                navText: ['', ''],
            });
    } else {
        const elSlidesCont = document.querySelector('.helper__box');
        const swipe = new Swipe(elSlidesCont, 70);

        swipe.onLeft = () => switchSlide('next');
        swipe.onRight = () => switchSlide('prev');

        document.querySelector('.helper__main-square-click-left')?.addEventListener('click', () => {
            switchSlide('prev');
        });

        elSlidesCont?.addEventListener('click', () => {
            switchSlide('next');
        });
    }
}

elHelperBtn?.addEventListener('click', () => {
    fetch(url)
        .then((data) => {
            if (data.ok) {
                return Promise.resolve(data.text());
            }

            return Promise.reject(new Error(`Ошибка в загрузке helper: ${data.status}`));
        })
        .then((data) => {
            elHelperBtn.insertAdjacentHTML('afterend', `${data}`);

            const popupHelper = new Popup('popup', document?.querySelector('.helper__popup'));

            popupHelper.open();
            initHelper();
            loadSrcOrBg($('.helper__item-img'));
        });
});