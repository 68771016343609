import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { eksmoBP } from 'Lib/bp/bp';
import { speed } from 'Lib/speed/speed';
import { onScreen } from 'Lib/on-screen/on-screen';
import { eventSender } from 'Lib/event-sender/event-sender';
import { getW } from 'Lib/w/w';
import { Popup } from 'Lib/popup/popup';
import { formatPrice } from 'Lib/format-price/format-price';
import 'imports-loader?wrapper=window!jquery-throttle-debounce'; // eslint-disable-line import/no-extraneous-dependencies, import/no-webpack-loader-syntax
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';
import { getCookie } from 'Lib/cookies/cookies';

const $parent = $('.csearch:not(.csearch_popup)');
const $parentPopupOrNot = getW() < eksmoBP.smMax ? $('.csearch_popup') : $parent;
const $body = $('body');
const cBodyActive = 'body-csearch-active';
const sCont = '.csearch__cont';
const sForm = '.csearch__form';
const $style = $('.csearch__style');
const cInput = '.csearch__input';
const $input = $(cInput);
const cActive = 'csearch_active';
const $submit = $('.csearch__submit');
const cClose = '.csearch__close';
const cLoader = '.csearch__loader';
const cEraser = '.csearch__eraser';
const cDropDown = '.csearch__drop-down';
const cSubmitShow = 'csearch__submit_show';
const cCloseShow = 'csearch__close_show';
const cLoaderShow = 'csearch__loader_show';
const cEraserShow = 'csearch__eraser_show';
const cDropDownShow = 'csearch__drop-down_show';
const sInputItem = '.csearch__item';
const cHiddenFormShowed = 'csearch_hidden-form-showed';
const sLinkToShowAll = '.csearch__submit-link';
const cLinkToShowAllShow = 'csearch__submit-link_show';
const $header = $('.cheader');
const cHeaderFixed = 'cheader_fixed';
const cBookLink = 'csearch__item_book';
const sBookLink = `.${cBookLink}`;
const sPopupBoxCont = '.csearch__popup-box-cont';
let category = 'all_types';
let hrefBtn = '';
let textBtn = '';
const cTypesMobileBtn = '.csearch__types-mobile-btn';
const popupCsearch = new Popup('popup', document?.querySelector('.csearch_popup'));
const popupTypesMobile = new Popup('popup', document?.querySelector('.csearch__types-mobile-popup'));

function showSubmit(show = true) {
    $submit.toggleClass(cSubmitShow, show);
}

function showClose(show = true) {
    $(cClose).toggleClass(cCloseShow, show);
}

function showloader(show = true) {
    $(cLoader).toggleClass(cLoaderShow, show);
}

function showEraser(show = true) {
    $(cEraser).toggleClass(cEraserShow, show);
}

function showDropDown(isShow = true) {
    if ($(cDropDown).length) {
        if (getW() < eksmoBP.lgMin) {
            if (isShow) {
                disableBodyScroll($(cDropDown)[0]);
            } else {
                enableBodyScroll($(cDropDown)[0]);
            }
        }

        $(cDropDown).toggleClass(cDropDownShow, isShow);
        $body.find(sLinkToShowAll).toggleClass(cLinkToShowAllShow, isShow);
    }
}

function setActiveState(state = true) {
    if (state) {
        if (getW() < eksmoBP.smMax) {
            const searchHTML = $parent.html();

            $parent.html('');
            $(sPopupBoxCont).append(searchHTML);
            popupCsearch.open();
            $parentPopupOrNot.find(cInput).focus();

            setTimeout(() => {
                $(cInput).focus();
            }, speed);
        }

        $body.addClass(cBodyActive);
        $parentPopupOrNot.addClass(cActive);
        showClose();
        showSubmit();
        $parentPopupOrNot.addClass(cHiddenFormShowed);
    } else {
        $body.removeClass(cBodyActive);
        $parentPopupOrNot.removeClass(cActive);
        showClose(false);
        showSubmit(false);
        $parentPopupOrNot.find(cInput).val('');
        showloader(false);
        showEraser(false);
        showDropDown(false);
        $parentPopupOrNot.removeClass(cHiddenFormShowed);

        if (getW() < eksmoBP.smMax) {
            const csearchCont = $parentPopupOrNot.find(sPopupBoxCont);
            const searchHTML = csearchCont.html();

            csearchCont.html('');
            $parent.append(searchHTML);
            popupCsearch.close();
        }
    }
}

function findText(name, val) {
    const firstFindedLetter = name.toLowerCase().indexOf(val.toLowerCase());

    if (firstFindedLetter + 1) {
        const findedText = name.substr(firstFindedLetter, val.length);
        const result = `<span class="drop-down__finded-word">${findedText}</span>`;

        return name.replace(findedText, result);
    }

    return false;
}

function setWidthHiddenFormOnDesctop(isHeaderFixed) {
    if ($style.length) {
        if (isHeaderFixed && getW() > eksmoBP.smMax) {
            const $cont = $(sCont);
            const $menu = $('.cmenu').first();
            const $firstItemMenu = $menu.find('.cmenu__item').first();

            if ($cont.length && $menu.length && $firstItemMenu.length) {
                const wRes = $cont.position().left + $cont.outerWidth(true) - $menu.position().left - $firstItemMenu.outerWidth(true);

                $style.css({ width: wRes, position: 'absolute', right: 0 });

                if ($(cDropDown).length && getW() > eksmoBP.mdMax) {
                    $(cDropDown).css({ width: wRes, left: `calc(50% - ${wRes / 2}px)`, right: 0 });
                }
            }
        } else {
            $style.removeAttr('style');

            if ($(cDropDown).length) {
                $(cDropDown).removeAttr('style');
            }
        }
    }
}

// Установка поиска по разделам
$('.csearch__type-drop-down-item, .csearch__types-item').on('click', function () {
    const $th = $(this);
    const $parentType = $th.closest('.link-drop-down_active');
    const dataAction = $th.attr('data-action');

    category = $th.attr('data-category');
    // установка текста кнопки и её урла, если ничего не найдено во время поиска
    hrefBtn = $th.attr('data-href-btn-nothing-found');
    textBtn = $th.attr('data-text-btn-nothing-found');
    //
    $parentPopupOrNot.find('.csearch__category').val(category);

    if (getW() > eksmoBP.smMax) {
        $th.closest('.link-drop-down_active').removeClass('link-drop-down_active');
        $parentType.find('.csearch__type-drop-down-item').removeClass('csearch__type-drop-down-item_active');
        $parentType.find('.csearch__type-selected').text($th.text());
        $th.addClass('csearch__type-drop-down-item_active');
    } else {
        $(cTypesMobileBtn).text($th.text().toLowerCase());
        popupTypesMobile.close();
    }

    if (dataAction) {
        $(sForm).attr('action', $th.attr('data-action'));
    } else {
        $(sForm).attr('action', '/search/');
    }
});

if ($parent.length) {
    $parent.on('focus', '.csearch__input', () => {
        setActiveState();
    });

    $body.on('click', '.csearch__close-box', () => {
        setActiveState(false);
    });

    $body.on('click', cEraser, () => {
        $(cInput).val('');
        showDropDown(false);
        $(cInput).focus();
        showEraser(false);
    });

    $body.on('click', '.csearch__hidden-form-button', () => {
        setActiveState();
    });

    $(cTypesMobileBtn).on('click', () => {
        popupTypesMobile.open();
    });

    // нажатие на клавиши клавиатуры
    $body.on('keyup paste click', '.csearch__input, .csearch__types-item, .csearch__type-drop-down-item', (e) => {
        if (
            e.keyCode !== 40
            && e.keyCode !== 38
            && e.keyCode !== 27
            && e.keyCode !== 13
            && e.keyCode !== 16
            && e.keyCode !== 17
            && e.keyCode !== 18
            && e.keyCode !== 19
            && e.keyCode !== 20
            && e.keyCode !== 33
            && e.keyCode !== 34
            && e.keyCode !== 35
            && e.keyCode !== 36
            && e.keyCode !== 37
            && e.keyCode !== 39
            && e.keyCode !== 44
            && e.keyCode !== 45
            && e.keyCode !== 91
            && e.keyCode !== 144
        ) { // кнопки, не обновляющие список такие как alt и shift
            const valLen = $(cInput).val().trim().length;

            showEraser(!!valLen);
            showloader((valLen > 2));
        }
    });

    // нажатие на клавиши клавиатуры с задержкой
    $body.on('keyup paste click', '.csearch__input, .csearch__types-item, .csearch__type-drop-down-item', $.debounce(speed * 3, (e) => {
        const href = $(cDropDown).data('href');
        const val = $parentPopupOrNot.find(cInput).val().trim();

        if (val.length > 2 && typeof href === 'string') {
            if (
                e.keyCode !== 40
                && e.keyCode !== 38
                && e.keyCode !== 27
                && e.keyCode !== 13
                && e.keyCode !== 16
                && e.keyCode !== 17
                && e.keyCode !== 18
                && e.keyCode !== 19
                && e.keyCode !== 20
                && e.keyCode !== 33
                && e.keyCode !== 34
                && e.keyCode !== 35
                && e.keyCode !== 36
                && e.keyCode !== 37
                && e.keyCode !== 39
                && e.keyCode !== 44
                && e.keyCode !== 45
                && e.keyCode !== 91
                && e.keyCode !== 144
            ) { // исключая кнопки не обновляющие список такие как alt и shift
                $.post(href, { 'q': val, 'category': category }, (data) => {
                    let dataParse = data;

                    if (Object.prototype.toString.call(dataParse) === '[object String]') {
                        dataParse = JSON.parse(data);
                    }

                    $(cDropDown).empty();
                    showDropDown();
                    $(cDropDown).scrollTop(0);
                    $(cDropDown).append('<div class="drop-down drop-down_shadow drop-down_bradius csearch__drop-down-wrapper"></div>');

                    const $dropDownWrapper = $(cDropDown).find('.csearch__drop-down-wrapper');
                    let html = '';

                    function addBooksList(item, nameSection, age) {
                        html += `<div class="csearch__drop-down-title">${nameSection}</div>`;
                        html += '<div class="csearch__items-book">';

                        for (let i = 0; i < item.length; i++) {
                            if (i >= 3) {
                                break;
                            }

                            let stylePic = ' style="background-color:#f7f7f7;"';
                            let author = '';
                            let format = '';
                            let price = '';
                            let name = item[i].name;
                            const nameReplaced = findText(name, val);

                            if (nameReplaced) {
                                name = nameReplaced;
                            }

                            if (typeof item[i].image !== 'undefined' && item[i].image !== '') {
                                stylePic = ` style="background-image:url(${item[i].image});"`;
                            }

                            if (typeof item[i].author !== 'undefined') {
                                let tempAuthor = '';

                                for (let a = 0; a < item[i].author.length; a++) {
                                    const aName = item[i].author[a][0];

                                    if (aName !== '') {
                                        if (tempAuthor !== '') {
                                            tempAuthor += ', ';
                                        }

                                        tempAuthor += aName;
                                    }
                                }

                                if (tempAuthor !== '') {
                                    author = `<div class="csearch__book-author">${tempAuthor}</div>`;
                                }
                            }

                            if (isNoEmptyString(item[i].price_list.PEREPLET)) {
                                format = `<div class="csearch__book-format">${item[i].price_list.PEREPLET}</div>`;
                            }

                            if (
                                item[i].price_list.style !== 'na'
                                && typeof item[i].price_list.price !== 'undefined'
                                && item[i].price_list.price > 0
                                && typeof item[i].price_list.currency !== 'undefined'
                                && item[i].price_list.price !== ''
                            ) {
                                price = `
                                    <div class="csearch__book-price">${item[i].price_list.price} ${item[i].price_list.currency}</div>
                                `;
                            }

                            html += `
                                <a
                                    href="${item[i].url}"
                                    class="drop-down__item csearch__item csearch__item_book${item[i].age18 && age ? ' popup18 popup18_active' : ''}"
                                    data-id="${item[i].price_list.NOMCODE}"
                                    data-category="${item[i].category || ''}"
                                    data-brand="${item[i].brand || ''}"
                                    data-pos="${i + 1}"
                                    data-available="${item[i].available || ''}"
                                    data-preorder="${item[i].price_list?.preorder || ''}"
                                    data-old-price="${item[i].price_list?.oldprice || ''}"
                                    data-ga-ecommerce-name="${item[i]['ga-ecommerce'] || ''}"
                                >
                                    <div class="csearch__book">
                                        <div class="csearch__book-pic${item[i].age18 && age ? ' popup18__img' : ''}"${stylePic}></div>
                                        <div class="csearch__book-text">
                                            <div class="csearch__book-name">${name}</div>
                                            ${author}${format}${price}
                                        </div>
                                    </div>
                                </a>
                            `;
                        }

                        html += '</div>';
                    }

                    if (Object.prototype.toString.call(dataParse) === '[object Object]') {
                        Object.keys(dataParse).forEach((key) => {
                            const item = dataParse[key];
                            const age = getCookie(`EKSMO_BOOKS_POPUP_18`) !== 'Y';

                            if (item.length) {
                                if (key === 'digest') {
                                    let name = item[0].name;
                                    const nameReplaced = findText(name, val);

                                    if (nameReplaced) {
                                        name = nameReplaced;
                                    }

                                    html += '<div class="csearch__drop-down-title">Дайджесты</div>';
                                    html += '<div class="csearch__items-digest">';

                                    html += `
                                        <a href="${item[0].url}" class="drop-down__item csearch__item">
                                            <div class="csearch__digest">
                                                <div class="csearch__digest-pic">
                                                    <img src="${item[0].image}" alt="${item[0].name}" class="csearch__digest-img">
                                                </div>
                                                <div class="csearch__digest-name">${name}</div>
                                            </div>
                                        </a>
                                    `;

                                    html += '</div>';
                                } else if (key === 'books' && (category === 'all_types' || category === 'books')) {
                                    addBooksList(item, 'Книги', age);
                                } else if (key === 'complect' && (category === 'all_types' || category === 'complect')) {
                                    addBooksList(item, 'Комплекты', age);
                                } else if (key === 'authors' && (category === 'all_types' || category === 'authors')) {
                                    html += '<div class="csearch__drop-down-title">Авторы</div>';
                                    html += '<div class="csearch__items-author">';

                                    for (let i = 0; i < item.length; i++) {
                                        if (i >= 4) {
                                            break;
                                        }

                                        let stylePic = '';
                                        let name = item[i].name;
                                        const nameReplaced = findText(name, val);

                                        if (nameReplaced) {
                                            name = nameReplaced;
                                        }

                                        if (typeof item[i].image !== 'undefined' && item[i].image !== '') {
                                            stylePic = ` style="background-image:url(${item[i].image});"`;
                                        }

                                        html += `
                                            <a href="${item[i].url}" class="drop-down__item csearch__item csearch__item_author">
                                                <div class="csearch__author">
                                                    <div class="csearch__author-pic"${stylePic}></div>
                                                    <div class="csearch__author-name">${name}</div>
                                                </div>
                                            </a>
                                        `;
                                    }

                                    html += '</div>';
                                } else if (key === 'series' && (category === 'all_types' || category === 'series')) {
                                    html += '<div class="csearch__drop-down-title">Серии</div>';
                                    html += '<div class="csearch__items-series">';

                                    for (let i = 0; i < item.length; i++) {
                                        if (i >= 4) {
                                            break;
                                        }

                                        let name = item[i].name;
                                        const nameReplaced = findText(name, val);

                                        if (nameReplaced) {
                                            name = nameReplaced;
                                        }

                                        html += `
                                            <a href="${item[i].url}" class="drop-down__item csearch__item">
                                                <div class="csearch__series">${name}</div>
                                            </a>
                                        `;
                                    }

                                    html += '</div>';
                                } else if (key === 'podborki' && (category === 'all_types' || category === 'collections')) {
                                    html += '<div class="csearch__drop-down-title">Подборки</div>';
                                    html += '<div class="csearch__items-podborki">';

                                    for (let i = 0; i < item.length; i++) {
                                        if (i >= 4) {
                                            break;
                                        }

                                        let name = item[i].name;
                                        const nameReplaced = findText(name, val);

                                        if (nameReplaced) {
                                            name = nameReplaced;
                                        }

                                        html += `
                                            <a href="${item[i].url}" class="drop-down__item csearch__item">
                                                <div class="csearch__podborki">${name}</div>
                                            </a>
                                        `;
                                    }

                                    html += '</div>';
                                } else if (key === 'articles' && (category === 'all_types' || category === 'articles')) {
                                    html += '<div class="csearch__drop-down-title">Статьи</div>';
                                    html += '<div class="csearch__items-articles">';

                                    for (let i = 0; i < item.length; i++) {
                                        if (i >= 4) {
                                            break;
                                        }

                                        let name = item[i].name;
                                        const nameReplaced = findText(name, val);

                                        if (nameReplaced) {
                                            name = nameReplaced;
                                        }

                                        html += `
                                            <a href="${item[i].url}" class="drop-down__item csearch__item">
                                                <div class="csearch__articles">${name}</div>
                                            </a>
                                        `;
                                    }

                                    html += '</div>';
                                }
                            }
                        });

                        if (html === '') {
                            showDropDown(false);
                            $(cDropDown).empty();
                        } else {
                            html += '<div class="csearch__submit-link csearch__submit-link_d"><div class="btn btn_orange btn_full-width">Все результаты поиска</div></div>';
                        }

                        if (!$parentPopupOrNot.find('.csearch__submit-link_m').length) {
                            $parentPopupOrNot.find(sCont).append('<div class="csearch__submit-link csearch__submit-link_m"><div class="btn btn_orange btn_full-width">Все результаты поиска</div></div>');
                        }

                        setTimeout(() => {
                            onScreen($('.csearch__item_book'), ($book) => {
                                const price = Number(formatPrice($book.find('.csearch__book-price').text()));
                                const oldPrice = Number(formatPrice($book.attr('data-old-price')));
                                const isPreorder = Number(formatPrice($book.attr('data-preorder')));

                                eventSender.impressionsGA4({
                                    price,
                                    name: $book.find('.csearch__book-name').text().trim(),
                                    id: $book.attr('data-id'),
                                    oldPrice,
                                    isPreorder,
                                    discountPrice: oldPrice && price ? oldPrice - price : null,
                                    brand: $book.attr('data-brand'),
                                    author: $book.find('.csearch__book-author').text().trim(),
                                    firstCategory: '', // на бэке нет возможности отправлять 1-ю категорию
                                    lastCategory: $book.attr('data-category'),
                                    type: $book.find('.csearch__book-format').text(),
                                    list: $book.attr('data-ga-ecommerce-name'),
                                    index: $book.attr('data-pos'),
                                });
                            }, 0, 0, 0.8);
                        }, speed);
                    } else {
                        const btnDesktop = getW() > eksmoBP.mdMin && textBtn ? `<a href="${hrefBtn}" class="csearch__find-empty-btn btn btn_orange">${textBtn}</a>` : '';

                        html += `
                            <div class="csearch__find-empty">
                                <div class="csearch__find-empty-title">По вашему запросу ничего не найдено</div>
                                <div class="csearch__find-empty-subtitle">Измените запрос или поищите в другом разделе</div>
                                ${btnDesktop}
                            </div>
                        `;

                        if (textBtn) {
                            $parentPopupOrNot.find('.csearch__submit-link_m').remove();
                            $parentPopupOrNot.find(sCont).append(`<a href="${hrefBtn}" class="csearch__submit-link csearch__submit-link_m"><div class="btn btn_orange btn_full-width">${textBtn}</div></a>`);
                        } else {
                            $parentPopupOrNot.find('.csearch__submit-link_m').remove();
                        }
                    }

                    $dropDownWrapper.append(html);

                    if (html.length) {
                        showDropDown();
                    }

                    showloader(false);
                });

                $(cDropDown).attr('data-item-num', '-1');
            }
        } else {
            showDropDown(false);
            $(cDropDown).empty();
            showloader(false);
        }
    }));

    $body.on('keydown', '.csearch__input', function (e) {
        const $this = $(this);
        let dropDownNum = parseInt($(cDropDown).attr('data-item-num'));

        if (e.keyCode === 40) { // down key
            if ($(cDropDown).find('.drop-down__item').length !== 0) {
                $(cDropDown).find(sInputItem).removeClass('drop-down__item_active');
                dropDownNum++;
                $(cDropDown).attr('data-item-num', dropDownNum);

                const $itemActive = $(cDropDown).find(sInputItem).eq(dropDownNum);

                if (dropDownNum > $(cDropDown).find(sInputItem).length - 1) {
                    $(cDropDown).attr('data-item-num', '-1');
                } else {
                    $itemActive.addClass('drop-down__item_active');

                    if (typeof $itemActive.attr('href') !== 'string') {
                        $this.val($.trim($itemActive.text()));
                    }
                }
            }
        } else if (e.keyCode === 38) { // up key
            if ($(cDropDown).find('.drop-down__item').length) {
                $(cDropDown).find(sInputItem).removeClass('drop-down__item_active');

                if (dropDownNum > -1) {
                    dropDownNum--;
                    $(cDropDown).attr('data-item-num', dropDownNum);
                } else {
                    dropDownNum = $(cDropDown).find(sInputItem).length - 1;
                    $(cDropDown).attr('data-item-num', dropDownNum);
                }

                if (dropDownNum > -1) {
                    const $itemActive = $(cDropDown).find(sInputItem).eq(dropDownNum);

                    $itemActive.addClass('drop-down__item_active');

                    if (typeof $itemActive.attr('href') !== 'string') {
                        $this.val($.trim($itemActive.text()));
                    }
                }
            }
        } else if (e.keyCode === 27) { // esc key
            $this.blur();
            setActiveState(false);
            $(cDropDown).attr('data-item-num', '-1');
        } else if (e.keyCode === 13) { // enter key
            if (dropDownNum > -1) {
                const $itemActive = $(cDropDown).find(sInputItem).eq(dropDownNum);

                if (typeof $itemActive.attr('href') === 'string') {
                    e.preventDefault();
                    document.location = $itemActive.attr('href');
                }
            }
        }
    });

    $body.on('keyup', '.csearch__input', function (e) {
        const $this = $(this);
        const dropDownNum = parseInt($(cDropDown).attr('data-item-num'));
        const val = $.trim($this.val());

        if (e.keyCode !== 40 && e.keyCode !== 38 && e.keyCode !== 27) { // down up esc
            $this.attr('data-val', val);
        }

        // down or up key
        if (e.keyCode === 40 || e.keyCode === 38) {
            if ($(cDropDown).find('.drop-down__item').length !== 0 && dropDownNum === -1) {
                $this.val($this.attr('data-val'));
            }
        }
    });

    // нажатие на пункт выпадающего списка поиска
    $parentPopupOrNot.on('click', sInputItem, function () {
        const $this = $(this);

        if (typeof $this.attr('href') !== 'string') {
            $input.val($.trim($this.text()));
            $parentPopupOrNot.find(sForm).submit();
        }
    });

    $parentPopupOrNot.on('click', sLinkToShowAll, () => {
        $parentPopupOrNot.find(sForm).submit();
    });

    $(cDropDown).on('scroll', () => {
        $input.blur();
    });

    $(document).click((e) => {
        if (getW() > eksmoBP.mdMin) {
            if ($(e.target).closest($parentPopupOrNot).length) {
                return;
            }

            setActiveState(false);
            e.stopPropagation();
        }
    });

    $header.on('eksmoHeaderFixed', (e, isFixed) => {
        setWidthHiddenFormOnDesctop(isFixed);
    });

    $body.on('eksmoResizeWidth', () => {
        setWidthHiddenFormOnDesctop($header.hasClass(cHeaderFixed));
    });

    $parentPopupOrNot.on('click', sBookLink, function () {
        const $book = $(this);
        const price = Number(formatPrice($book.find('.csearch__book-price').text()));
        const oldPrice = Number(formatPrice($book.attr('data-old-price')));
        const isPreorder = Number(formatPrice($book.attr('data-preorder')));

        eventSender.clickGA4({
            price,
            name: $book.find('.csearch__book-name').text().trim(),
            id: $book.attr('data-id'),
            oldPrice,
            isPreorder,
            discountPrice: oldPrice && price ? oldPrice - price : null,
            brand: $book.attr('data-brand'),
            author: $book.find('.csearch__book-author').text().trim(),
            firstCategory: '', // на бэке нет возможности отправлять 1-ю категорию
            lastCategory: $book.attr('data-category'),
            type: $book.find('.csearch__book-format').text(),
            list: $book.attr('data-ga-ecommerce-name'),
            index: $book.attr('data-pos'),
        });
    });
}